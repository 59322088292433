import React from "react";
import { FaPlus } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const Meetings = () => {
  const navigate = useNavigate();

  if (1 === 0) {
    return (
      <div className="h-screen w-full flex items-center justify-center">
        <div>
          <div>
            <img src="/onboard/empty.svg" alt="empty" />
          </div>
          <p>Уулзалтын загвар үүсгээгүй байна</p>
        </div>
      </div>
    );
  }

  return (
    <div className="pt-11 w-10/12 m-auto">
      <div className="w-full p-6 bg-[#fff] rounded-xl">
        <div className="w-full flex items-center justify-between mb-8">
          <p className="text-[24px] text-[#222] ">Үүсгэсэн уулзалтууд</p>
          <button
            onClick={() => navigate("create")}
            className="ps-6 pe-4 py-3 bg-[#324d72] text-sm text-[#fff] flex items-center gap-3 rounded-xl"
          >
            Шинээр үүсгэх
            <FaPlus />
          </button>
        </div>
        <div className="h-[68vh] overflow-x-scroll flex flex-wrap gap-6 ">
          <div
            onClick={() => {
              navigate("id");
            }}
            className="p-3 border border-[#E5E6E8] rounded-xl flex flex-col justify-between w-[225px] h-[75px] bg-[#fff] cursor-pointer"
          >
            <div className="flex items-center justify-between">
              <div>
                <p className="text-[#1E293B] text-sm">Meeting name</p>
                <p className="text-[#575763] text-xs flex items-center">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        d="M7.5 0.75H4.5V1.75H7.5V0.75ZM5.5 7.25H6.5V4.25H5.5V7.25ZM9.515 3.945L10.225 3.235C10.01 2.98 9.775 2.74 9.52 2.53L8.81 3.24C8.035 2.62 7.06 2.25 6 2.25C3.515 2.25 1.5 4.265 1.5 6.75C1.5 9.235 3.51 11.25 6 11.25C8.49 11.25 10.5 9.235 10.5 6.75C10.5 5.69 10.13 4.715 9.515 3.945ZM6 10.25C4.065 10.25 2.5 8.685 2.5 6.75C2.5 4.815 4.065 3.25 6 3.25C7.935 3.25 9.5 4.815 9.5 6.75C9.5 8.685 7.935 10.25 6 10.25Z"
                        fill="#575763"
                      />
                    </svg>
                  </span>{" "}
                  30 мин
                </p>
              </div>
              <div className="flex items-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                >
                  <path
                    d="M5.99998 9.66683C4.43998 9.66683 1.33331 10.4468 1.33331 12.0002V13.1668H10.6666V12.0002C10.6666 10.4468 7.55998 9.66683 5.99998 9.66683ZM2.89331 11.8335C3.45331 11.4468 4.80665 11.0002 5.99998 11.0002C7.19331 11.0002 8.54665 11.4468 9.10665 11.8335H2.89331ZM5.99998 8.50016C7.28665 8.50016 8.33331 7.4535 8.33331 6.16683C8.33331 4.88016 7.28665 3.8335 5.99998 3.8335C4.71331 3.8335 3.66665 4.88016 3.66665 6.16683C3.66665 7.4535 4.71331 8.50016 5.99998 8.50016ZM5.99998 5.16683C6.55331 5.16683 6.99998 5.6135 6.99998 6.16683C6.99998 6.72016 6.55331 7.16683 5.99998 7.16683C5.44665 7.16683 4.99998 6.72016 4.99998 6.16683C4.99998 5.6135 5.44665 5.16683 5.99998 5.16683ZM10.6933 9.70683C11.4666 10.2668 12 11.0135 12 12.0002V13.1668H14.6666V12.0002C14.6666 10.6535 12.3333 9.88683 10.6933 9.70683ZM9.99998 8.50016C11.2866 8.50016 12.3333 7.4535 12.3333 6.16683C12.3333 4.88016 11.2866 3.8335 9.99998 3.8335C9.63998 3.8335 9.30665 3.92016 8.99998 4.06683C9.41998 4.66016 9.66665 5.38683 9.66665 6.16683C9.66665 6.94683 9.41998 7.6735 8.99998 8.26683C9.30665 8.4135 9.63998 8.50016 9.99998 8.50016Z"
                    fill="#575763"
                  />
                </svg>
                <p className="text-[#575763] text-sm">30</p>
              </div>
            </div>
            <div className="flex justify-end">
              <p className="text-[#575763] text-xs">
                үүсгэсэн: <span>DD/MM/YYY</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Meetings;
