import React, { useState } from "react";
import { FaAngleRight, FaMinus, FaPlus } from "react-icons/fa6";
import { FaAngleLeft } from "react-icons/fa6";
import dayjs from "dayjs";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";
import {
  LineChart,
  Line,
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ZAxis,
} from "recharts";
import "../../css/additional-styles/costum-burnout-input.scss";

const InternalEmp = () => {
  const [startDate, setStartDate] = useState(
    dayjs().startOf("week").add(1, "day")
  );
  const [selectedSurvey, setSelectedSurvey] = useState("Alltime");
  const [dropSurvey, setDropSurvey] = useState(false);

  const options = [
    {
      value: "Alltime",
      label: "Бүх цаг үеийн",
    },
    { value: "ThisYear", label: "Энэ жил" },
    {
      value: "LastMonth",
      label: "Сүүлийн сар",
    },
  ];

  const feedbackData = [
    { month: "01", value: 1000 },
    { month: "02", value: 0 },
    { month: "03", value: 0 },
    { month: "04", value: 400 },
    { month: "05", value: 400 },
    { month: "06", value: 0 },
    { month: "07", value: 0 },
    { month: "08", value: 0 },
    { month: "09", value: 0 },
    { month: "10", value: 0 },
    { month: "11", value: 0 },
    { month: "12", value: 0 },
  ];

  const surveyData = [
    { month: "01", value: 3, name: "Survey name", completion: 70, count: 25 },
    { month: "02", value: 2, name: "Survey name", completion: 50, count: 20 },
    { month: "04", value: 4, name: "Survey name", completion: 80, count: 30 },
    { month: "06", value: 4, name: "Survey name", completion: 70, count: 25 },
    { month: "12", value: 5, name: "Survey name", completion: 70, count: 25 },
  ];

  const completedSurveyData = Array.from({ length: 12 }, (_, i) => {
    const month = (i + 1).toString().padStart(2, "0");
    const existingData = surveyData.find((d) => d.month === month);
    return (
      existingData || {
        month,
        value: 0,
        name: "No data",
        completion: 0,
        count: 0,
      }
    );
  });

  const burnoutData = [
    { name: "John Doe", burnoutAvg: 3.5, month: "January" },
    { name: "Jane Smith", burnoutAvg: 4.2, month: "February" },
    { name: "Michael Johnson", burnoutAvg: 2.8, month: "March" },
    { name: "Emily Davis", burnoutAvg: 3.9, month: "April" },
    { name: "Daniel Wilson", burnoutAvg: 4.5, month: "May" },
    { name: "Liam Miller", burnoutAvg: 2.7, month: "July" },
    { name: "Sophia Martinez", burnoutAvg: 4.0, month: "August" },
    { name: "William Anderson", burnoutAvg: 3.6, month: "September" },
    { name: "James White", burnoutAvg: 2.9, month: "November" },
    { name: "Charlotte Harris", burnoutAvg: 4.3, month: "December" },
  ];

  const allMonths = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const completeBurnoutData = allMonths.map((month, index) => {
    const dataPoint = burnoutData.find((data) => data.month === month);
    return {
      month: month,
      monthNumber: index + 1,
      burnoutAvg: dataPoint ? dataPoint.burnoutAvg : 0,
    };
  });

  const data = [
    { x: 2, y: 5, z: 1, name: "Андройд Алтанхуяг", role: "Product Manager" },
    { x: 4, y: 15, z: 2, name: "Жавхаа Батсүх", role: "Developer" },
    { x: 10, y: 20, z: 2, name: "Сараа Цолмон", role: "Designer" },
  ];

  const CustomTooltipSurvey = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: "#333",
            color: "#fff",
            padding: "10px",
            borderRadius: "8px",
          }}
        >
          <p className="text-xs font-semibold">{payload[0].payload.name}</p>
          <p className="text-xs font-semibold">👥 {payload[0].payload.count}</p>
          <p className="text-xs">{payload[0].payload.completion}% гүйцэтгэл</p>
        </div>
      );
    }
    return null;
  };

  const CustomTooltipFeedback = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { name, role } = payload[0].payload;
      return (
        <div
          style={{
            background: "#fff",
            padding: "10px",
            borderRadius: "8px",
            boxShadow: "0 0 10px rgba(0,0,0,0.1)",
          }}
        >
          <p>{name}</p>
          <p style={{ fontSize: "12px", color: "#666" }}>{role}</p>
        </div>
      );
    }
    return null;
  };

  const CustomTooltipBurnout = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: "#333",
            color: "#fff",
            padding: "10px",
            borderRadius: "8px",
          }}
        >
          <p>Ерөнхий Burnout- {payload[0].payload.burnoutAvg}</p>
          <div>
            <img className="w-[142px]" src="/burnoutRe.png" alt="emote" />
            <div className="burnout-range-wrapper mt-2 flex items-center gap-1">
              <FaMinus className="text-xs text-[#e63946]" />
              <input
                type="range"
                className="w-full burnout-range"
                value={payload[0].payload.burnoutAvg}
                min={1}
                max={5}
                step={0.1}
                readOnly
              />
              <FaPlus className="text-xs text-[#4caf50]" />
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  const handleNextWeek = () => {
    setStartDate((prevDate) => prevDate.add(7, "day"));
  };

  const handlePrevWeek = () => {
    setStartDate((prevDate) => prevDate.subtract(7, "day"));
  };

  const daysOfWeek = Array.from({ length: 7 }, (_, index) =>
    startDate.add(index, "day")
  );

  const customYSurvey = ({ x, y, payload }) => {
    if (!payload) return null;

    console.log("Y-Axis Payload:", payload);

    const colors = {
      0: "#999999",
      1: "#00D97D",
      2: "#7EEFB2",
      3: "#FFE070",
      4: "#FFBD70",
      5: "#FF5F6E",
    };

    return (
      <text
        x={x - 10}
        y={y + 5}
        textAnchor="end"
        fill={colors[payload.value] || "#000"}
        fontSize={12}
        className="text-sm"
      >
        {payload.value}
      </text>
    );
  };

  return (
    <div>
      <div className="p-4 bg-[#fff] w-full rounded-xl mb-6">
        <div className="mb-5 flex items-center justify-between">
          <p className="text-[#1E293B]">Удахгүй тохиох эвентүүд</p>
          <div className="flex items-center bg-[#F9F9F9] py-1 px-2 rounded-lg gap-2">
            <button onClick={handlePrevWeek}>
              <FaAngleLeft className="text-[#1E293B]" />
            </button>
            <p className="text-[#1E293B] text-sm">
              {daysOfWeek[0].format("D MMM")} -{" "}
              {daysOfWeek[6].format("D MMM YYYY")}
            </p>
            <button onClick={handleNextWeek}>
              <FaAngleRight className="text-[#1E293B]" />
            </button>
          </div>
        </div>
        <div className="flex items-center justify-center rounded-xl overflow-x-hidden">
          {daysOfWeek.map((day, index) => (
            <div
              key={index}
              className={`border border-[#D9D9D9] ${
                index === 0 ? "rounded-tl-xl rounded-bl-xl" : ""
              } ${
                index === 6 ? "rounded-tr-xl rounded-br-xl" : ""
              } w-[123px] h-[147px]`}
            >
              <div
                className={`bg-[#F9F9F9] w-full text-center px-5 py-3 h-[48px] ${
                  index === 0 ? "rounded-tl-xl" : ""
                } ${index === 6 ? "rounded-tr-xl" : ""}`}
              >
                <p className="text-[#1E293B] text-sm font-semibold">
                  {day.format("ddd D")}
                </p>
              </div>
              <div className="w-full h-[98px]"></div>
            </div>
          ))}
        </div>
      </div>
      <div className="p-4 bg-[#fff] w-full rounded-xl mb-6">
        <div className="flex items-center justify-between mb-5">
          <p className="text-[#1E293B]">Пульс судалгаа </p>
          <div className="flex items-center gap-3">
            <div className="relative">
              <div
                onClick={() => {
                  setDropSurvey(!dropSurvey);
                }}
                className="flex items-center gap-2 border border-[#ECECEC] text-[#222] text-xs rounded-xl px-3 py-2 cursor-pointer"
              >
                <span>
                  <MdOutlineCalendarMonth />
                </span>
                <span>
                  {options.find((opt) => opt.value === selectedSurvey).label}
                </span>
                <span>
                  <IoIosArrowDown
                    className={`transition-transform duration-300 ${
                      dropSurvey ? "rotate-180" : ""
                    }`}
                  />
                </span>
              </div>
              {dropSurvey && (
                <div className="absolute w-full top-full left-0 mt-2 bg-white border border-[#ECECEC] rounded-xl shadow-lg z-10 max-h-52 overflow-y-auto">
                  {options.map((option) => (
                    <div
                      key={option.value}
                      onClick={() => {
                        setSelectedSurvey(option.value);
                        setDropSurvey(!dropSurvey);
                      }}
                      className="flex items-center px-3 py-2 cursor-pointer hover:bg-gray-100 text-[#222] text-xs border-b border-[#ECECEC]"
                    >
                      {option.label}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <select className="text-[#222] border border-[#ECECEC] rounded-lg text-xs">
              <option defaultChecked disabled value="">
                Хэлтэс
              </option>
              <option value="IT">IT</option>
              <option value="design">Дизайн</option>
            </select>
          </div>
        </div>
        <div style={{ width: "100%", height: 220 }}>
          <ResponsiveContainer>
            <LineChart data={completedSurveyData}>
              <CartesianGrid strokeDasharray="3 3" vertical={false} />

              <XAxis
                dataKey="month"
                axisLine={false}
                tickLine={false}
                tick={({ payload, x, y }) => (
                  <text
                    x={x}
                    y={y + 10}
                    fill="#777985"
                    fontSize={14}
                    textAnchor="middle"
                  >
                    {payload.value}
                  </text>
                )}
              />

              <YAxis
                type="number"
                interval={0}
                domain={[0, 5]}
                ticks={[0, 1, 2, 3, 4, 5]}
                axisLine={false}
                tickLine={false}
                tick={customYSurvey}
              />

              <Tooltip content={<CustomTooltipSurvey />} />

              <Line
                type="linear"
                dataKey="value"
                stroke="#5A6ACF"
                strokeWidth={2}
                dot={{ r: 5, fill: "#5A6ACF" }}
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className="p-4 bg-[#fff] w-full rounded-xl mb-6">
        <div className="flex items-center justify-between mb-5">
          <p className="text-[#1E293B]">Burnout түвшин</p>
          <div className="flex items-center gap-3">
            <div className="relative">
              <div
                onClick={() => {
                  setDropSurvey(!dropSurvey);
                }}
                className="flex items-center gap-2 border border-[#ECECEC] text-[#222] text-xs rounded-xl px-3 py-2 cursor-pointer"
              >
                <span>
                  <MdOutlineCalendarMonth />
                </span>
                <span>
                  {options.find((opt) => opt.value === selectedSurvey).label}
                </span>
                <span>
                  <IoIosArrowDown
                    className={`transition-transform duration-300 ${
                      dropSurvey ? "rotate-180" : ""
                    }`}
                  />
                </span>
              </div>
              {dropSurvey && (
                <div className="absolute w-full top-full left-0 mt-2 bg-white border border-[#ECECEC] rounded-xl shadow-lg z-10 max-h-52 overflow-y-auto">
                  {options.map((option) => (
                    <div
                      key={option.value}
                      onClick={() => {
                        setSelectedSurvey(option.value);
                        setDropSurvey(!dropSurvey);
                      }}
                      className="flex items-center px-3 py-2 cursor-pointer hover:bg-gray-100 text-[#222] text-xs border-b border-[#ECECEC]"
                    >
                      {option.label}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <select className="text-[#222] border border-[#ECECEC] rounded-lg text-xs">
              <option defaultChecked disabled value="">
                Хэлтэс
              </option>
              <option value="IT">IT</option>
              <option value="design">Дизайн</option>
            </select>
          </div>
        </div>
        <div style={{ width: "100%", height: 220 }}>
          <ResponsiveContainer>
            <LineChart data={completeBurnoutData}>
              <CartesianGrid strokeDasharray="3 3" vertical={false} />

              <XAxis
                dataKey="monthNumber"
                axisLine={false}
                tickLine={false}
                tickFormatter={(value) => value}
                tick={({ payload, x, y }) => (
                  <text
                    x={x}
                    y={y + 10}
                    fill="#777985"
                    fontSize={14}
                    textAnchor="middle"
                  >
                    {payload.value}
                  </text>
                )}
              />

              <YAxis
                type="number"
                interval={0}
                domain={[0, 5]}
                ticks={[0, 1, 2, 3, 4, 5]}
                axisLine={false}
                tickLine={false}
                tick={customYSurvey}
              />

              <Tooltip content={<CustomTooltipBurnout />} />

              <Line
                type="linear"
                dataKey="burnoutAvg"
                stroke="#5A6ACF"
                strokeWidth={2}
                dot={{ r: 5, fill: "#5A6ACF" }}
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className="p-4 bg-[#fff] w-full rounded-xl mb-6">
        <div className="flex items-center justify-between mb-5">
          <p className="text-[#1E293B]">Feedback </p>
          <div className="flex items-center gap-3">
            <div className="relative">
              <div
                onClick={() => {
                  setDropSurvey(!dropSurvey);
                }}
                className="flex items-center gap-2 border border-[#ECECEC] text-[#222] text-xs rounded-xl px-3 py-2 cursor-pointer"
              >
                <span>
                  <MdOutlineCalendarMonth />
                </span>
                <span>
                  {options.find((opt) => opt.value === selectedSurvey).label}
                </span>
                <span>
                  <IoIosArrowDown
                    className={`transition-transform duration-300 ${
                      dropSurvey ? "rotate-180" : ""
                    }`}
                  />
                </span>
              </div>
              {dropSurvey && (
                <div className="absolute w-full top-full left-0 mt-2 bg-white border border-[#ECECEC] rounded-xl shadow-lg z-10 max-h-52 overflow-y-auto">
                  {options.map((option) => (
                    <div
                      key={option.value}
                      onClick={() => {
                        setSelectedSurvey(option.value);
                        setDropSurvey(!dropSurvey);
                      }}
                      className="flex items-center px-3 py-2 cursor-pointer hover:bg-gray-100 text-[#222] text-xs border-b border-[#ECECEC]"
                    >
                      {option.label}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <select className="text-[#222] border border-[#ECECEC] rounded-lg text-xs">
              <option defaultChecked disabled value="">
                Хэлтэс
              </option>
              <option value="IT">IT</option>
              <option value="design">Дизайн</option>
            </select>
          </div>
        </div>
        <div className="mb-5 flex items-center justify-center gap-10">
          <div>
            <p className="text-sm text-[#1E293B]">Нийт: 988 фидбэк</p>
            <p className="text-xs text-[#1E293B]">Оролцоо: 30%</p>
          </div>
          <div className="py-3 px-4 flex items-center justify-between bg-[#F3E8FF] rounded-xl w-[212px]">
            <div>
              <p className="text-[#667085] text-sm">Start</p>
              <p className="text-[#1E293B] font-semibold">12</p>
            </div>
            <img src="/dashboard/start.svg" alt="icon" />
          </div>
          <div className="py-3 px-4 flex items-center justify-between bg-[#FFF4DE] rounded-xl w-[212px]">
            <div>
              <p className="text-[#667085] text-sm">Stop</p>
              <p className="text-[#1E293B] font-semibold">12</p>
            </div>
            <img src="/dashboard/stop.svg" alt="icon" />
          </div>
          <div className="py-3 px-4 flex items-center justify-between bg-[#DCFCE7] rounded-xl w-[212px]">
            <div>
              <p className="text-[#667085] text-sm">Continue</p>
              <p className="text-[#1E293B] font-semibold">12</p>
            </div>
            <img src="/dashboard/con.svg" alt="icon" />
          </div>
        </div>

        <div style={{ width: "100%", height: 300 }}>
          <ResponsiveContainer>
            <LineChart data={feedbackData}>
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis
                dataKey="month"
                axisLine={false}
                tickLine={false}
                tick={({ payload, x, y }) => {
                  return (
                    <text
                      x={x}
                      y={y + 10}
                      fill={"#777985"}
                      fontSize={14}
                      textAnchor="middle"
                    >
                      {parseInt(payload.value, 10)}
                    </text>
                  );
                }}
              />
              <YAxis
                allowDecimals={false}
                domain={[0, 1000]}
                ticks={[1000, 800, 600, 400, 200, 0]}
                allowDataOverflow={true}
                interval={0}
                axisLine={false}
                tickLine={false}
                tick={({ payload, x, y }) => {
                  return (
                    <text
                      x={x - 5}
                      y={y + 5}
                      textAnchor="end"
                      fontSize={12}
                      fill="#777985"
                    >
                      {payload.value}
                    </text>
                  );
                }}
              />

              <Tooltip />
              <Line
                type="linear"
                dataKey="value"
                stroke="#5A6ACF"
                strokeWidth={2}
                dot={{ r: 5, fill: "#5A6ACF" }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className="p-4 bg-[#fff] w-full rounded-xl mb-6">
        <div className="flex items-center justify-end mb-5">
          <div className="flex items-center gap-3">
            <div className="relative">
              <div
                onClick={() => {
                  setDropSurvey(!dropSurvey);
                }}
                className="flex items-center gap-2 border border-[#ECECEC] text-[#222] text-xs rounded-xl px-3 py-2 cursor-pointer"
              >
                <span>
                  <MdOutlineCalendarMonth />
                </span>
                <span>
                  {options.find((opt) => opt.value === selectedSurvey).label}
                </span>
                <span>
                  <IoIosArrowDown
                    className={`transition-transform duration-300 ${
                      dropSurvey ? "rotate-180" : ""
                    }`}
                  />
                </span>
              </div>
              {dropSurvey && (
                <div className="absolute w-full top-full left-0 mt-2 bg-white border border-[#ECECEC] rounded-xl shadow-lg z-10 max-h-52 overflow-y-auto">
                  {options.map((option) => (
                    <div
                      key={option.value}
                      onClick={() => {
                        setSelectedSurvey(option.value);
                        setDropSurvey(!dropSurvey);
                      }}
                      className="flex items-center px-3 py-2 cursor-pointer hover:bg-gray-100 text-[#222] text-xs border-b border-[#ECECEC]"
                    >
                      {option.label}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <select className="text-[#222] border border-[#ECECEC] rounded-lg text-xs">
              <option defaultChecked disabled value="">
                Хэлтэс
              </option>
              <option value="IT">IT</option>
              <option value="design">Дизайн</option>
            </select>
          </div>
        </div>
        <ResponsiveContainer width="100%" height={420}>
          <ScatterChart>
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis
              type="number"
              dataKey="x"
              name="Tenure"
              unit="yrs"
              domain={[0, 50]}
              axisLine={false}
              tickLine={false}
              ticks={[0, 10, 20, 30, 40, 50]}
              tick={{ dy: 10 }}
              label={{ value: "Tenure", position: "insideBottom", offset: 2 }}
              interval={0}
            />
            <YAxis
              type="number"
              dataKey="y"
              name="Feedback"
              unit=""
              axisLine={false}
              tickLine={false}
              domain={[0, 30]}
              ticks={[0, 6, 12, 18, 24, 30]}
              label={{ value: "Feedback", angle: -90, position: "insideLeft" }}
            />
            <ZAxis type="number" dataKey="z" range={[100, 100]} />
            <Tooltip content={<CustomTooltipFeedback />} />
            <Scatter data={data} fill="#7196BF" opacity={60} />
          </ScatterChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default InternalEmp;
