import React, { useState } from "react";
import { FaPlus } from "react-icons/fa6";
import MainTable from "../../components/table/MainTable";
import { useNavigate } from "react-router-dom";

const Applicants = () => {
  const [sel, setSel] = useState("open");
  const navigate = useNavigate();

  const data = [
    {
      name: "Тоног төхөөрөмжийн цахилгаан инженер",
      createdDate: "2024-08-28 16:45",
      status: "Нээлттэй",
      anket: 40,
    },
    {
      name: "Барилгын хяналтын инженер",
      createdDate: "2024-08-28 16:45",
      status: "Нээлттэй",
      anket: 4,
    },
    {
      name: "ХАБЭА-Н инженер",
      createdDate: "2024-08-28 16:45",
      status: "Нээлттэй",
      anket: 1,
    },
    {
      name: "Дизель хөдөлгүүрийн техникч",
      createdDate: "2024-08-28 16:45",
      status: "Нээлттэй",
      anket: 12,
    },
    {
      name: "Экскаваторын оператор",
      createdDate: "2024-08-28 16:45",
      status: "Нээлттэй",
      anket: 36,
    },
  ];

  return (
    <div className="pt-11 w-10/12 m-auto">
      <div className="flex justify-between items-center mb-6">
        <p className="text-[#222] text-[24px]">Ажлын байрууд</p>
        <button
          onClick={() => {
            navigate("create");
          }}
          className="rounded-lg px-6 py-3 flex items-center gap-2 text-sm text-[#fff] bg-[#324d72]"
        >
          Шинэ ажлын байр <FaPlus />
        </button>
      </div>
      <div className="flex items-center gap-6">
        <button
          onClick={() => {
            setSel("open");
          }}
          className={`pb-2 ${
            sel === "open"
              ? "text-[#FD6B3A] border-b-2 border-[#FD6B3A]"
              : "text-[#4B4B53]"
          }`}
        >
          Идэвхитэй
        </button>
        <button
          onClick={() => {
            setSel("closed");
          }}
          className={`pb-2 ${
            sel === "closed"
              ? "text-[#FD6B3A] border-b-2 border-[#FD6B3A]"
              : "text-[#4B4B53]"
          }`}
        >
          Хаагдсан
        </button>
      </div>
      <div>
        <div className="w-full mb-2 mt-6">
          <MainTable
            columnDefs={[
              {
                headerName: "Тестүүд",
                field: "text",
                sortable: true,
                sortable: true,

                flex: 2,
                headerComponent: (hprops) => {
                  return (
                    <div className="flex justify-center items-center gap-1">
                      <img src="/survey/icon/text.svg" alt="icon" />
                      <p className="text-[##1E293B] text-sm font-normal">
                        {hprops.displayName}
                      </p>
                    </div>
                  );
                },
                headerComponentParams: { menuIcon: "/icons/house.png" },
              },
              {
                headerName: "Үүсгэсэн огноо",
                field: "createdDate",
                sortable: true,
                sortable: true,

                flex: 2,
                headerComponent: (hprops) => {
                  return (
                    <div className="flex justify-center items-center gap-1">
                      <img src="/survey/icon/created.svg" alt="icon" />
                      <p className="text-[##1E293B] text-sm font-normal">
                        {hprops.displayName}
                      </p>
                    </div>
                  );
                },
                headerComponentParams: { menuIcon: "/icons/calendar.png" },
              },
              {
                headerName: "Статус",
                field: "status",
                sortable: true,
                sortable: true,

                flex: 2,
                headerComponent: (hprops) => {
                  return (
                    <div className="flex justify-center items-center gap-1">
                      <img src="/survey/icon/question.svg" alt="icon" />
                      <p className="text-[##1E293B] text-sm font-normal">
                        {hprops.displayName}
                      </p>
                    </div>
                  );
                },
                headerComponentParams: { menuIcon: "/icons/chart.png" },
              },
              {
                headerName: "Анкет",
                field: "anket",
                sortable: true,
                sortable: true,
                flex: 2,

                headerComponent: (hprops) => {
                  return (
                    <div className="flex justify-center items-center gap-1">
                      <img src="/survey/icon/employee.svg" alt="icon" />
                      <p className="text-[##1E293B] text-sm font-normal">
                        {hprops.displayName}
                      </p>
                    </div>
                  );
                },
                headerComponentParams: { menuIcon: "/icons/location.png" },
              },
            ]}
            rowData={data.map((elm) => {
              return {
                text: elm.name,
                createdDate: elm.createdDate.substring(0, 16),
                status: elm.status,
                anket: elm.anket,
              };
            })}
          />
        </div>
      </div>
    </div>
  );
};

export default Applicants;
