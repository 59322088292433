import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
} from "chart.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Bar, Doughnut } from "react-chartjs-2";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Legend);

const AdaptionEmp = ({ data }) => {
  const { t } = useTranslation();
  const [experienceYearCounts, setExperienceYearCounts] = useState({});
  const [workWithData, setWorkWithData] = useState({});
  const [genderCounts, setGenderCounts] = useState({
    male: 0,
    women: 0,
    others: 0,
    null: 0,
  });
  const [selectedSurvey, setSelectedSurvey] = useState("Alltime");
  const [dropSurvey, setDropSurvey] = useState(false);

  const options = [
    {
      value: "Alltime",
      label: "Бүх цаг үеийн",
    },
    { value: "ThisYear", label: "Энэ жил" },
    {
      value: "LastMonth",
      label: "Сүүлийн сар",
    },
  ];

  const empData = [
    { month: "01", value: 1000 },
    { month: "02", value: 0 },
    { month: "03", value: 0 },
    { month: "04", value: 400 },
    { month: "05", value: 400 },
    { month: "06", value: 0 },
    { month: "07", value: 0 },
    { month: "08", value: 0 },
    { month: "09", value: 0 },
    { month: "10", value: 0 },
    { month: "11", value: 0 },
    { month: "12", value: 0 },
  ];

  useEffect(() => {
    if (data.length > 0) {
      // Gender Counts
      const genderData = data.reduce(
        (acc, item) => {
          if (item.gender === "MALE") acc.male += 1;
          else if (item.gender === "WOMEN") acc.women += 1;
          else if (item.gender === "OTHER") acc.others += 1;
          else acc.null += 1;
          return acc;
        },
        { male: 0, women: 0, others: 0, null: 0 }
      );
      setGenderCounts(genderData);

      // Experience Year Counts
      const experienceYears = data
        .map((item) => item.major_experience_year)
        .filter((year) => year !== null);
      const expCounts = experienceYears.reduce((counts, year) => {
        counts[year] = (counts[year] || 0) + 1;
        return counts;
      }, {});
      const expTotal = Object.values(expCounts).reduce(
        (sum, count) => sum + count,
        0
      );
      const expPercentages = Object.keys(expCounts).reduce((obj, year) => {
        obj[year] = Math.round((expCounts[year] / expTotal) * 100);
        return obj;
      }, {});
      setExperienceYearCounts(expPercentages);

      // Work With Data Counts
      const workWithValues = data
        .map((item) => item.work_with)
        .filter((value) => value !== null);
      const workWithCounts = workWithValues.reduce((counts, value) => {
        counts[value] = (counts[value] || 0) + 1;
        return counts;
      }, {});
      const workWithTotal = Object.values(workWithCounts).reduce(
        (sum, count) => sum + count,
        0
      );
      const workWithPercentages = Object.keys(workWithCounts).reduce(
        (obj, value) => {
          obj[value] = Math.round(
            (workWithCounts[value] / workWithTotal) * 100
          );
          return obj;
        },
        {}
      );
      setWorkWithData(
        Object.keys(workWithCounts).reduce((obj, value) => {
          obj[value] = {
            count: workWithCounts[value],
            percentage: workWithPercentages[value],
          };
          return obj;
        }, {})
      );
    }
  }, [data]);

  const highestTop5Percentages = Object.entries(workWithData)
    .sort(([, a], [, b]) => b.percentage - a.percentage)
    .slice(0, 5);

  const doghChart = {
    labels: ["Эмэгтэй", "Бусад", "Эрэгтэй"],
    datasets: [
      {
        data: [genderCounts.women, genderCounts.others, genderCounts.male],
        backgroundColor: ["#FF9CE9", "#6CB7A1", "#A7BAFF"],
        hoverBackgroundColor: ["#FF9CE9", "#6CB7A1", "#A7BAFF"],
        cutout: "70%",
        borderRadius: 6,
      },
    ],
  };

  const yearServiceChart = {
    labels: Object.keys(experienceYearCounts),
    datasets: [
      {
        data: Object.values(experienceYearCounts),
        backgroundColor: ["#6CB7A1", "#324D72", "#FF9D72", "#66BCCE"],
        hoverBackgroundColor: ["#6CB7A1", "#324D72", "#FF9D72", "#66BCCE"],
        barThickness: 20,
        borderRadius: 4,
      },
    ],
  };

  const sectorChart = {
    labels: highestTop5Percentages.map(([value]) => value),
    datasets: [
      {
        data: highestTop5Percentages.map(([, { percentage }]) => percentage),
        backgroundColor: [
          "#6CB7A1",
          "#324D72",
          "#FF9D72",
          "#66BCCE",
          "#2851BB",
        ],
        borderRadius: 4,
        barThickness: 12,
      },
    ],
  };

  return (
    <div>
      <div className="bg-[#fff] w-full rounded-xl px-5 py-4 mb-10">
        <div className="w-full rounded-xl mb-6">
          <div className="flex items-center justify-between mb-5">
            <p className="text-[#1E293B]">Сонгон шалгаруулалт </p>
            <div className="flex items-center gap-3">
              <div className="relative">
                <div
                  onClick={() => {
                    setDropSurvey(!dropSurvey);
                  }}
                  className="flex items-center gap-2 border border-[#ECECEC] text-[#222] text-xs rounded-xl px-3 py-2 cursor-pointer"
                >
                  <span>
                    <MdOutlineCalendarMonth />
                  </span>
                  <span>
                    {options.find((opt) => opt.value === selectedSurvey).label}
                  </span>
                  <span>
                    <IoIosArrowDown
                      className={`transition-transform duration-300 ${
                        dropSurvey ? "rotate-180" : ""
                      }`}
                    />
                  </span>
                </div>
                {dropSurvey && (
                  <div className="absolute w-full top-full left-0 mt-2 bg-white border border-[#ECECEC] rounded-xl shadow-lg z-10 max-h-52 overflow-y-auto">
                    {options.map((option) => (
                      <div
                        key={option.value}
                        onClick={() => {
                          setSelectedSurvey(option.value);
                          setDropSurvey(!dropSurvey);
                        }}
                        className="flex items-center px-3 py-2 cursor-pointer hover:bg-gray-100 text-[#222] text-xs border-b border-[#ECECEC]"
                      >
                        {option.label}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <select className="text-[#222] border border-[#ECECEC] rounded-lg text-xs">
                <option defaultChecked disabled value="">
                  Хэлтэс
                </option>
                <option value="IT">IT</option>
                <option value="design">Дизайн</option>
              </select>
            </div>
          </div>
          <div>
            <p className="text-[#202224] text-sm font-semibold">
              Нийт ажил горилогч
            </p>
          </div>
          <div className="mb-6" style={{ width: "100%", height: 300 }}>
            <ResponsiveContainer>
              <LineChart data={empData}>
                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                <XAxis
                  dataKey="month"
                  axisLine={false}
                  tickLine={false}
                  tick={({ payload, x, y }) => {
                    return (
                      <text
                        x={x}
                        y={y + 10}
                        fill={"#777985"}
                        fontSize={14}
                        textAnchor="middle"
                      >
                        {parseInt(payload.value, 10)}
                      </text>
                    );
                  }}
                />
                <YAxis
                  allowDecimals={false}
                  domain={[0, 1000]}
                  ticks={[1000, 800, 600, 400, 200, 0]}
                  allowDataOverflow={true}
                  axisLine={false}
                  tickLine={false}
                  tick={({ payload, x, y }) => {
                    return (
                      <text
                        x={x - 5}
                        y={y + 5}
                        textAnchor="end"
                        fontSize={12}
                        fill="#777985"
                      >
                        {payload.value}
                      </text>
                    );
                  }}
                />

                <Tooltip />
                <Line
                  type="linear"
                  dataKey="value"
                  stroke="#5A6ACF"
                  strokeWidth={2}
                  dot={{ r: 5, fill: "#5A6ACF" }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className="flex items-center justify-between">
          <div>
            <p className="text-sm text-[#324d72] font-bold">
              {t("Dashboard.genderPercentage")}
            </p>
            <div className="flex flex-col justify-center mt-3">
              <div className="flex justify-center">
                <div style={{ width: "120px", height: "120px" }}>
                  <Doughnut
                    data={doghChart}
                    options={{
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                    }}
                  />
                </div>
              </div>
              <div className="flex items-center justify-center gap-4 mt-2">
                <div className="flex items-center gap-1">
                  <div className="w-2 h-2 bg-[#FF9CE9] rounded-full" />
                  <p className="text-[10px] opacity-80">{t("Form.men")}</p>
                </div>
                <div className="flex items-center gap-1">
                  <div className="w-2 h-2 bg-[#6CB7A1] rounded-full" />
                  <p className="text-[10px] opacity-80">{t("Form.other")}</p>
                </div>
                <div className="flex items-center gap-1">
                  <div className="w-2 h-2 bg-[#A7BAFF] rounded-full" />
                  <p className="text-[10px] opacity-80">{t("Form.women")}</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <p className="text-sm text-[#324d72] font-bold">
              {t("Dashboard.byWorkingYear")}
            </p>
            <div className="flex flex-col justify-center mt-8 ">
              <div className="flex items-center">
                <div>
                  <div className="flex items-start">
                    <div className="flex flex-col justify-between h-[120px]">
                      <p className="text-[#1C1C1C] text-xs opacity-40 ">100</p>
                      <p className="text-[#1C1C1C] text-xs opacity-40 ">75</p>
                      <p className="text-[#1C1C1C] text-xs opacity-40 ">50</p>
                      <p className="text-[#1C1C1C] text-xs opacity-40 ">25</p>
                      <p className="text-[#1C1C1C] text-xs opacity-40 ">0</p>
                    </div>
                    <div className="w-[220px]">
                      <Bar
                        data={yearServiceChart}
                        options={{
                          elements: {
                            bar: {
                              borderWidth: 1,
                            },
                          },
                          plugins: { legend: { display: false } },
                          scales: {
                            x: { display: false },
                            y: { display: false, max: 100 },
                          },
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex ms-7 flex-wrap items-center justify-between px-1 me-1">
                    <p className="text-[#1C1C1C] text-xs opacity-40">0-1</p>
                    <p className="text-[#1C1C1C] text-xs opacity-40">1-3</p>
                    <p className="text-[#1C1C1C] text-xs opacity-40">4-7</p>
                    <p className="text-[#1C1C1C] text-xs opacity-40">7-10</p>
                    <p className="text-[#1C1C1C] text-xs opacity-40">10+</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <p className="text-sm text-[#324d72] font-bold mb-2">
              {t("Dashboard.bySector")}
            </p>
            <div className="flex flex-col justify-center">
              <div className="flex items-center">
                <div>
                  <div className="flex justify-center">
                    <div className="flex items-start">
                      <div className="flex flex-col justify-between h-[135px] w-[30px]">
                        <p className="text-[#1C1C1C] text-xs opacity-40 ">
                          100
                        </p>
                        <p className="text-[#1C1C1C] text-xs opacity-40 ">75</p>
                        <p className="text-[#1C1C1C] text-xs opacity-40 ">50</p>
                        <p className="text-[#1C1C1C] text-xs opacity-40 ">25</p>
                        <p className="text-[#1C1C1C] text-xs opacity-40 ">0</p>
                      </div>
                      <div className="w-[250px]">
                        <Bar
                          data={sectorChart}
                          options={{
                            elements: {
                              bar: {
                                borderWidth: 1,
                              },
                            },
                            plugins: { legend: { display: false } },
                            scales: {
                              x: { display: false },
                              y: { display: false, max: 100 },
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-wrap items-center justify-center gap-3">
                    {sectorChart.labels.map((label, index) => (
                      <div className="flex items-center" key={index}>
                        <div
                          className={`w-[10px] h-[10px] rounded-full bg-${sectorChart.datasets[0].backgroundColor[index]} me-1`}
                          style={{
                            backgroundColor:
                              sectorChart.datasets[0].backgroundColor[index],
                          }}
                        />
                        <p className="text-[10px]">{label}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdaptionEmp;
