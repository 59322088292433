import React, { useState } from "react";
import MainTable from "../../../components/table/MainTable";
import { IoMdClose } from "react-icons/io";

const MeetingTable = () => {
  const [data, setData] = useState([
    {
      name: "Emily Carter",
      date: "2025-02-10",
      schedule_day: "Tuesday",
      time: "9:00 AM - 3:00 PM",
      age: 28,
      status: "Employed",
      interested_profession: "Graphic Designer",
      industry: "Creative Arts",
      phone: "+1 987-654-3210",
      address: "45 Sunset Blvd, Los Angeles, CA",
      is_fulltime: false,
      can_work_above_3_months: true,
    },
    {
      name: "James Miller",
      date: "2025-02-11",
      schedule_day: "Wednesday",
      time: "11:00 AM - 5:00 PM",
      age: 32,
      status: "Freelancer",
      interested_profession: "Data Analyst",
      industry: "Finance",
      phone: "+1 555-123-4567",
      address: "789 Oak St, New York, NY",
      is_fulltime: true,
      can_work_above_3_months: false,
    },
    {
      name: "Sophia Lee",
      date: "2025-02-12",
      schedule_day: "Thursday",
      time: "8:00 AM - 2:00 PM",
      age: 24,
      status: "Student",
      interested_profession: "Marketing Specialist",
      industry: "Advertising",
      phone: "+1 222-333-4444",
      address: "12 Maple Ave, Chicago, IL",
      is_fulltime: false,
      can_work_above_3_months: true,
    },
    {
      name: "Daniel Smith",
      date: "2025-02-13",
      schedule_day: "Friday",
      time: "1:00 PM - 7:00 PM",
      age: 30,
      status: "Unemployed",
      interested_profession: "Project Manager",
      industry: "Construction",
      phone: "+1 777-888-9999",
      address: "67 Pine St, Houston, TX",
      is_fulltime: true,
      can_work_above_3_months: true,
    },
    {
      name: "Olivia Brown",
      date: "2025-02-14",
      schedule_day: "Saturday",
      time: "10:00 AM - 4:00 PM",
      age: 27,
      status: "Looking for Work",
      interested_profession: "HR Specialist",
      industry: "Human Resources",
      phone: "+1 444-555-6666",
      address: "98 Birch Rd, Seattle, WA",
      is_fulltime: false,
      can_work_above_3_months: false,
    },
  ]);
  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}/${month}/${year}`;
  };

  const [modalData, setModalData] = useState(null);

  return (
    <div className="relative">
      {" "}
      <MainTable
        columnDefs={[
          {
            headerName: "Candidates",
            field: "name",
            sortable: true,
            flex: 2,
            headerComponent: (hprops) => {
              return (
                <div className="flex justify-center items-center text-[#1E293B]">
                  {hprops.menuIcon != null ? (
                    <img
                      src={`${hprops.menuIcon}`}
                      className="mr-1.5"
                      width={20}
                      height={20}
                    />
                  ) : null}
                  {hprops.displayName}
                </div>
              );
            },
            headerComponentParams: { menuIcon: "/icons/house.png" },
          },
          {
            headerName: "Товлосон өдөр",
            field: "schedule_day",
            sortable: true,
            flex: 2,
            headerComponent: (hprops) => {
              return (
                <div className="flex justify-center items-center text-[#1E293B]">
                  {hprops.menuIcon != null ? (
                    <img
                      src={`${hprops.menuIcon}`}
                      className="mr-1.5"
                      width={20}
                      height={20}
                    />
                  ) : null}
                  {hprops.displayName}
                </div>
              );
            },
            headerComponentParams: { menuIcon: "/icons/calendar.png" },
          },
          {
            headerName: "Товлосон цаг",
            field: "time",
            sortable: true,
            flex: 2,
            headerComponent: (hprops) => {
              return (
                <div className="flex justify-center items-center text-[#1E293B]">
                  {hprops.menuIcon != null ? (
                    <img
                      src={`${hprops.menuIcon}`}
                      className="mr-1.5"
                      width={20}
                      height={20}
                    />
                  ) : null}
                  {hprops.displayName}
                </div>
              );
            },
            headerComponentParams: { menuIcon: "/icons/time.svg" },
          },
          {
            headerName: "Бүртгэлийн мэдээлэл",
            field: "allAnket",
            sortable: true,
            flex: 2,
            headerComponent: (hprops) => {
              return (
                <div className="flex justify-center items-center text-[#1E293B]">
                  {hprops.menuIcon != null ? (
                    <img
                      src={`${hprops.menuIcon}`}
                      className="mr-1.5"
                      width={20}
                      height={20}
                    />
                  ) : null}
                  {hprops.displayName}
                </div>
              );
            },
            cellRenderer: (params) => {
              return (
                <div className="flex items-center justify-center">
                  <button
                    onClick={() => {
                      console.log(params);
                      setModalData(params.data);
                    }}
                    className="text-[#324D72] p-1 rounded-md border border-[#324D72] text-sm hover:opacity-50"
                  >
                    Мэдээлэл харах
                  </button>
                </div>
              );
            },
            headerComponentParams: { menuIcon: "/icons/doc.svg" },
          },
        ]}
        rowData={data.map((elm) => {
          return {
            name: elm.name,
            date: elm.date,
            schedule_day: elm.schedule_day,
            time: elm.time,
            age: elm.age,
            status: elm.status,
            interested_profession: elm.interested_profession,
            industry: elm.industry,
            phone: elm.phone,
            address: elm.address,
            is_fulltime: elm.is_fulltime,
            can_work_above_3_months: elm.can_work_above_3_months,
          };
        })}
      />
      {modalData && (
        <div className="w-full h-full fixed left-0 top-0 bg-[#000] bg-opacity-40 flex items-center justify-center z-50">
          <div className="max-w-[500px] p-3 bg-[#fff] rounded-xl">
            <div className="flex items-center justify-end mb-6">
              <button
                onClick={() => setModalData(null)}
                className="p-1 border-2 border-[#CECFD3] rounded-md"
              >
                <IoMdClose className="text-[#707070]" />
              </button>
            </div>
            <div className="flex items-center justify-between gap-4 mb-8">
              <p className="text-[#1A1A1A] font-semibold">Уулзалт нэр</p>
              <p className="text-[#475569] text-xs text-end">
                {formatDate(modalData.date)}-д уулзалтын цаг товлосон
              </p>
            </div>
            <div className="flex items-center justify-between gap-4 mb-3">
              <p className="text-[#1E293B] text-[12px]">Нэр</p>
              <p className="text-[#1E293B] text-[12px]">{modalData.name}</p>
            </div>
            <div className="flex items-center justify-between gap-4 mb-3">
              <p className="text-[#1E293B] text-[12px]">Овог</p>
              <p className="text-[#1E293B] text-[12px]">{modalData.name}</p>
            </div>
            <div className="flex items-center justify-between gap-4 mb-3">
              <p className="text-[#1E293B] text-[12px]">Нас</p>
              <p className="text-[#1E293B] text-[12px]">{modalData.age}</p>
            </div>
            <div className="flex items-center justify-between gap-4 mb-3">
              <p className="text-[#1E293B] text-[12px]">Одоогийн статус</p>
              <p className="text-[#1E293B] text-[12px]">{modalData.status}</p>
            </div>
            <div className="flex items-center justify-between gap-4 mb-3">
              <p className="text-[#1E293B] text-[12px]">
                Сонирхож буй ажлын байр
              </p>
              <p className="text-[#1E293B] text-[12px]">
                {modalData.interested_profession}
              </p>
            </div>

            <div className="flex items-center justify-between gap-4 mb-3">
              <p className="text-[#1E293B] text-[12px]">
                Ажиллах боломжтой салбар
              </p>
              <p className="text-[#1E293B] text-[12px]">{modalData.industry}</p>
            </div>
            <div className="flex items-center justify-between gap-4 mb-3">
              <p className="text-[#1E293B] text-[12px]">Утасны дугаар</p>
              <p className="text-[#1E293B] text-[12px]">{modalData.phone}</p>
            </div>
            <div className="flex items-center justify-between gap-4 mb-3">
              <p className="text-[#1E293B] text-[12px]">Гэрийн хаяг</p>
              <p className="text-[#1E293B] text-[12px]">{modalData.address}</p>
            </div>
            <div className="flex items-center justify-between gap-4 mb-3">
              <p className="text-[#1E293B] text-[12px]">
                Бүтэн цагаар ажиллах <br />
                боломжтой эсэх
              </p>
              <p className="text-[#1E293B] text-[12px]">
                {modalData.is_fulltime ? "Боломжтой" : "Боломжгүй"}
              </p>
            </div>
            <div className="flex items-center justify-between gap-4 mb-3">
              <p className="text-[#1E293B] text-[12px]">
                3 сараас дээш хугацаанд <br /> ажиллах боломжтой эсэх
              </p>
              <p className="text-[#1E293B] text-[12px]">
                {modalData.can_work_above_3_months ? "Боломжтой" : "Боломжгүй"}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MeetingTable;
