import React, { useState } from "react";
import { LuClock9 } from "react-icons/lu";
import { createMeeting } from "../../../services/meeting.service";
import { useNavigate } from "react-router-dom";

const CreateMeeting = () => {
  const [time, setTime] = useState("30");
  const [title, setTitle] = useState("");
  const [location, setLocation] = useState("");
  const [popUp, setPopUp] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [schedule, setSchedule] = useState([
    { day: "Monday", enabled: true, time: "09:00", endtime: "" },
    { day: "Tuesday", enabled: true, time: "09:00", endtime: "" },
    { day: "Wednesday", enabled: true, time: "09:00", endtime: "" },
    { day: "Thursday", enabled: true, time: "09:00", endtime: "" },
    { day: "Friday", enabled: true, time: "09:00", endtime: "" },
  ]);

  const toggleDay = (index) => {
    setSchedule((prev) =>
      prev.map((item, i) =>
        i === index ? { ...item, enabled: !item.enabled } : item
      )
    );
  };

  const changeTime = (index, newTime, type) => {
    if (type === "start") {
      setSchedule((prev) =>
        prev.map((item, i) => (i === index ? { ...item, time: newTime } : item))
      );
    } else {
      setSchedule((prev) =>
        prev.map((item, i) =>
          i === index ? { ...item, endtime: newTime } : item
        )
      );
    }
  };

  const isFormValid = () => {
    const hasEnabledSchedules = schedule.some((item) => item.enabled);
    const allEnabledHaveEndTime = schedule
      .filter((item) => item.enabled)
      .every((item) => item.endtime.trim() !== "");
    const isTitleValid = title.trim() !== "";
    const isLocationValid = location.trim() !== "";

    return (
      hasEnabledSchedules &&
      allEnabledHaveEndTime &&
      isTitleValid &&
      isLocationValid
    );
  };

  const submit = async () => {
    setLoading(true);
    const dayMapping = {
      Monday: 1,
      Tuesday: 2,
      Wednesday: 3,
      Thursday: 4,
      Friday: 5,
      Saturday: 6,
      Sunday: 7,
    };

    const schedules = schedule
      .filter((entry) => entry.enabled)
      .map((entry) => ({
        dayOfWeek: dayMapping[entry.day],
        startTime: entry.time,
        endTime: entry.endtime,
        isActive: entry.enabled,
      }));

    const submitData = {
      name: title,
      userId: 1,
      companyId: 101,
      duration: time,
      address: location,
      schedules: schedules,
    };

    try {
      await createMeeting(submitData);
      setPopUp(true);
      setLoading(false);
    } catch (err) {
      console.error("Error creating meeting:", err);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-10/12 m-auto pt-11 relative">
      <div>
        <p className="text-[#222] text-[24px] mb-10">
          Уулзалтын хуваарь үүсгэх
        </p>
        <div className="w-full flex items-start justify-between gap-10">
          <div className="w-[30%]">
            <p className="text-[#1E293B] mb-8">Уулзалтын мэдээлэл</p>
            <div>
              <div className="mb-6">
                <p className="text-[#111827] text-sm mb-2">
                  Уулзалтын нэр <span className="text-[#FF5F6E]">*</span>
                </p>
                <input
                  className="w-full rounded-xl text-sm p-3 border border-[#575763]"
                  placeholder="Ж/нь: ажлын ярилцлага"
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                  value={title}
                  type="text"
                />
              </div>

              <div className="mb-6">
                <p className="text-[#111827] text-sm mb-2">
                  Уулзалтын үргэлжлэх хугацаа{" "}
                  <span className="text-[#FF5F6E]">*</span>
                </p>
                <div className="relative w-full">
                  <select
                    onChange={(e) => {
                      setTime(e.target.value);
                    }}
                    value={time}
                    className="w-full rounded-xl text-sm p-3 border border-[#575763] appearance-none ps-8"
                  >
                    <option value="10">10 минут</option>
                    <option value="20">20 минут</option>
                    <option value="30">30 минут</option>
                    <option value="40">40 минут</option>
                    <option value="50">50 минут</option>
                    <option value="60">1 цаг</option>
                    <option value="70">1 цаг 10 минут</option>
                    <option value="80">1 цаг 20 минут</option>
                    <option value="90">1 цаг 30 минут</option>
                    <option value="100">1 цаг 40 минут</option>
                    <option value="110">1 цаг 50 минут</option>
                    <option value="120">2 цаг</option>
                  </select>
                  <LuClock9 className="absolute left-3 top-1/2 transform -translate-y-1/2 w-4 h-4 text-[#6B7280] pointer-events-none" />
                </div>
              </div>
              <div className="mb-6">
                <p className="text-[#111827] text-sm mb-2">
                  Уулзалт болох хаяг <span className="text-[#FF5F6E]">*</span>
                </p>
                <textarea
                  onChange={(e) => {
                    setLocation(e.target.value);
                  }}
                  value={location}
                  className="w-full h-[64px] rounded-xl text-sm p-3 border border-[#575763]"
                  placeholder="Энд бичнэ үү."
                ></textarea>
              </div>
            </div>
          </div>
          <div className="w-[70%]">
            <p className="text-[#1E293B] mb-8">
              Уулзалт товлох боломжтой өдөр, цагаа сонгоно уу.
            </p>
            <div>
              {schedule.map((item, index) => (
                <div
                  key={item.day}
                  className={`flex items-center justify-between pb-5  ${
                    index === 0 ? "" : "border-t border-[#D1D5DB] pt-4"
                  }`}
                >
                  <div className="flex items-center gap-2">
                    <label className="relative flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        className="sr-only"
                        checked={item.enabled}
                        onChange={() => toggleDay(index)}
                      />
                      <div
                        className={`w-[44px] h-[24px] rounded-2xl transition-colors ${
                          item.enabled ? "bg-[#2C3E5E]" : "bg-gray-300"
                        }`}
                      ></div>
                      <div
                        className={`absolute left-0.5 top-0.5 w-[20px] h-[20px] bg-white rounded-2xl shadow-md transition-transform ${
                          item.enabled ? "translate-x-5" : "translate-x-0"
                        }`}
                      ></div>
                    </label>
                    <p className="text-[#111827] text-sm ms-3">{item.day}</p>
                    {!item.enabled && (
                      <p className="text-[#4B5563] text-sm ">
                        Энэ өдөр уулзалт товлохгүй
                      </p>
                    )}
                  </div>
                  {item.enabled && (
                    <div className="flex items-center gap-3">
                      <input
                        type="time"
                        value={item.time}
                        disabled={!item.enabled}
                        onChange={(e) =>
                          changeTime(index, e.target.value, "start")
                        }
                        className={`border border-[#D1D5DB] rounded-md px-1 py-0.5 text-sm ${
                          item.enabled
                            ? "bg-white"
                            : "bg-gray-200 cursor-not-allowed"
                        }`}
                      />
                      <p className="text-[#4B5563] text-sm">to</p>
                      <input
                        type="time"
                        value={item.endtime}
                        disabled={!item.enabled}
                        onChange={(e) =>
                          changeTime(index, e.target.value, "end")
                        }
                        className={`border border-[#D1D5DB] rounded-md px-1 py-0.5 text-sm ${
                          item.enabled
                            ? "bg-white"
                            : "bg-gray-200 cursor-not-allowed"
                        }`}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="mt-10 flex justify-end">
          <button
            disabled={!isFormValid() || loading}
            onClick={submit}
            className={`px-6 py-2 text-[#FFF] rounded-md flex items-center justify-center ${
              isFormValid() && !loading
                ? "bg-[#324d72]"
                : "bg-[#CECFD3] cursor-default"
            }`}
          >
            {loading ? (
              <div className="w-5 h-5 border-4 border-t-4 border-white border-solid rounded-full animate-spin"></div>
            ) : (
              "Үүсгэх"
            )}
          </button>
        </div>
      </div>
      {popUp && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-[#fff]  w-[566px] rounded-xl h-[343px] pb-6 pt-4 flex items-center justify-center relative">
            <div className="mb-8">
              <div className="flex justify-center">
                {" "}
                <div className="bg-[#00D97D] bg-opacity-15 w-[50px] h-[50px] rounded-full flex justify-center items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                  >
                    <path
                      d="M14.6585 26.4585L7.70846 19.5085L5.3418 21.8585L14.6585 31.1752L34.6585 11.1752L32.3085 8.8252L14.6585 26.4585Z"
                      fill="#00D97D"
                    />
                  </svg>
                </div>
              </div>
              <div className="mt-8">
                <p className="text-[#1A1A1A] text-lg text-center font-semibold">
                  Уулзалт амжилттай үүсгэгдлээ.
                </p>
              </div>
            </div>
            <button
              onClick={() => {
                navigate("/company/meetings");
              }}
              className="bg-[#324d72] text-[#fff] text-sm px-3 py-2 rounded-lg absolute bottom-6 right-8"
            >
              Ойлголоо
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateMeeting;
