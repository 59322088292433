import React, { useEffect, useState } from "react";
import {
  getCompanyEmployee,
  getDepartments,
} from "../../../services/employee.service";
import Loading from "../../../components/uncommon/Loading";
import { FaRegBookmark, FaSpinner } from "react-icons/fa6";
import { LuPlay } from "react-icons/lu";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { createSoft, getEmail } from "../../../services/softSkill.service";
import ReactQuill from "react-quill";

const SoftSkillCreate = () => {
  const today = new Date();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [allChecked, setAllChecked] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [checkedEmployees, setCheckedEmployees] = useState({});
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [popUp, setPopUp] = useState(false);
  const [email, setEmail] = useState("");
  const [initialEmailText, setInitialEmailText] = useState("{{gameUrl}}");
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    getCompanyEmployee()
      .then((data) => {
        setEmployees(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    getDepartments()
      .then((data) => {
        setDepartments(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    getEmail()
      .then((data) => {
        setEmail(data.data);
        setInitialEmailText(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    setTitle(titleDate());
  }, []);

  const titleDate = () => {
    const now = new Date();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const getDepartmentName = (departmentId) => {
    const department = departments.find((dept) => dept.id === departmentId);
    return department ? department.name : "Unknown Department";
  };

  const employeesByDepartment = employees.reduce((dep, employee) => {
    const departmentId = employee.departmentId;
    const departmentName = getDepartmentName(departmentId);

    if (!dep[departmentName]) {
      dep[departmentName] = [];
    }

    dep[departmentName].push(employee);
    return dep;
  }, {});

  const handleCheckAll = () => {
    const updatedCheckedEmployees = {};
    const newAllChecked = !allChecked;

    employees.forEach((employee) => {
      updatedCheckedEmployees[employee.id] = newAllChecked;
    });

    Object.keys(employeesByDepartment).forEach((departmentName) => {
      updatedCheckedEmployees[departmentName] = newAllChecked;
    });

    setCheckedEmployees(updatedCheckedEmployees);
    setAllChecked(newAllChecked);

    if (newAllChecked) {
      setSelectedEmployees([...employees]);
    } else {
      setSelectedEmployees([]);
    }
  };

  const handleDepartmentCheck = (departmentName) => {
    const updatedCheckedEmployees = { ...checkedEmployees };
    const departmentChecked = updatedCheckedEmployees[departmentName];

    if (departmentChecked) {
      employeesByDepartment[departmentName].forEach((employee) => {
        updatedCheckedEmployees[employee.id] = false;
      });
    } else {
      employeesByDepartment[departmentName].forEach((employee) => {
        updatedCheckedEmployees[employee.id] = true;
      });
    }

    updatedCheckedEmployees[departmentName] = !departmentChecked;
    setCheckedEmployees(updatedCheckedEmployees);

    const selectedEmployees = employees.filter(
      (employee) => updatedCheckedEmployees[employee.id]
    );
    setSelectedEmployees(selectedEmployees);
    setAllChecked(selectedEmployees.length === employees.length);
  };

  const handleEmployeeCheck = (employeeId) => {
    const updatedCheckedEmployees = { ...checkedEmployees };
    updatedCheckedEmployees[employeeId] = !checkedEmployees[employeeId];
    setCheckedEmployees(updatedCheckedEmployees);

    const selectedEmployees = employees.filter(
      (employee) => updatedCheckedEmployees[employee.id]
    );
    setSelectedEmployees(selectedEmployees);
    setAllChecked(selectedEmployees.length === employees.length);
  };

  const filteredEmployeesByDepartment = Object.entries(employeesByDepartment)
    .map(([departmentName, departmentEmployees]) => {
      const filteredEmployees = departmentEmployees.filter((employee) =>
        `${employee.firstName} ${employee.lastName}`
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      );

      return filteredEmployees.length > 0
        ? [departmentName, filteredEmployees]
        : null;
    })
    .filter(Boolean);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `${month} сарын ${day}, ${year}`;
  };

  const createOna = async () => {
    setLoading(true);
    try {
      if (title === "") {
        toast.error(t("Survey.giveNameOnSurvey"));
        setLoading(false);
        return;
      }

      if (selectedEmployees.length === 0) {
        toast.error(t("Survey.checkEmployee"));
        setLoading(false);
        return;
      }

      const employeeData = selectedEmployees.map((employee) => ({
        employeeId: employee.id,
        employeeEmail: employee.email,
      }));

      const emailHasChanged = email !== initialEmailText;

      const softSkill = {
        softSkillName: title,
        emailTxt: emailHasChanged ? email : null,
        softSkillEmployee: employeeData,
      };

      console.log(softSkill);

      await createSoft(softSkill);
      console.log("success", softSkill);
      setLoading(false);
      setPopUp(true);
    } catch (error) {
      console.error("Error create the ona: ", error);
      toast.error(t("toast.Error"));
      setLoading(false);
    }
  };

  const handleChange = (value) => {
    const gameUrlPlaceholder = "{{gameUrl}}";
    if (value.includes(gameUrlPlaceholder)) {
      setEmail(value);
    } else {
      setEmail(value + " " + gameUrlPlaceholder);
    }
  };

  if (employees.length === 0 || departments.length === 0) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"],
    ["blockquote", "code-block"],

    [{ header: 1 }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ direction: "rtl" }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    ["clean"],
  ];

  return (
    <div className="bg-[#F3F4F8] min-h-screen">
      <div className="w-8/12 pt-11 m-auto">
        <div className="bg-white px-4 py-6 rounded-xl">
          <p className="text-[#222]">Soft skill тоглоомд нэр өгнө үү.</p>
          <div className="px-20">
            <input
              className="w-full text-sm border-[#D4D4D4] rounded-lg mt-3"
              placeholder="Энд бичнэ үү."
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              value={title}
              type="text"
            />
          </div>
        </div>
        <div className="bg-white mt-6 px-3 py-5 rounded-xl">
          <p className="mt-2 mb-2 font-bold text-center text-[14px]">
            {t("Assessment.pleaseChangeEmail")}
          </p>
          <ReactQuill
            className="text-sm"
            theme="snow"
            modules={{ toolbar: toolbarOptions }}
            value={email}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="mt-10">
          <p className="text-[#222]">
            Soft skill тоглоомд урих ажилчдыг сонгоно уу.
          </p>
          <div className="bg-white rounded-xl px-10 py-3">
            <div className="flex justify-between gap-2 items-center my-3">
              <input
                type="text"
                placeholder="Search employee..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-[300px] px-4 py-2 text-xs border border-[#BCBCBC] rounded-xl"
              />
              <div className="flex items-center gap-1">
                <input
                  className="bg-[#EBEBEB]  border-[#BCBCBC] rounded"
                  type="checkbox"
                  onClick={handleCheckAll}
                  checked={allChecked}
                />
                <p className="text-[#1E1E1E] text-xs">
                  {t("burnout.create.chooseAll")}
                </p>
              </div>
            </div>
            <div className=" max-h-[500px] overflow-y-auto">
              {filteredEmployeesByDepartment.map(
                ([departmentName, departmentEmployees]) => (
                  <div className="mb-4" key={departmentName}>
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        onChange={() => handleDepartmentCheck(departmentName)}
                        checked={checkedEmployees[departmentName] || false}
                        className="bg-[#EBEBEB]  border-[#BCBCBC] rounded"
                      />
                      <p className="text-sm font-semibold text-[#1E1E1E] ms-2 ">
                        {departmentName}
                      </p>
                    </div>
                    {departmentEmployees.map((employee) => (
                      <div
                        className="ms-4 flex items-center mt-3"
                        key={employee.id}
                      >
                        <div>
                          <input
                            type="checkbox"
                            onChange={() => handleEmployeeCheck(employee.id)}
                            checked={checkedEmployees[employee.id] || false}
                            className="bg-[#EBEBEB]  border-[#BCBCBC] rounded"
                          />
                          <label className="ms-2 text-sm text-[#1E1E1E]">
                            {employee.lastName}, {employee.firstName}
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                )
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-end pb-10 gap-7 mt-8">
          {/* <div className="flex flex-col items-center">
            <button
              className={` rounded-xl bg-[#E4FFEB] text-center py-3 px-4 hover:mb-2`}
              disabled={loading}
            >
              {loading ? (
                <span className="spinner animate-spin">
                  <FaSpinner />
                </span>
              ) : (
                <span>
                  <FaRegBookmark className="text-[#00962A] h-[18px] w-[14px]" />
                </span>
              )}
            </button>
            <p className="text-sm text-[#00962A] text-semibold text-center mt-1">
              {loading ? "Уншиж байна" : "Илгээх"}
            </p>
          </div>
          <div className="flex flex-col items-center">
            <button
              className={` rounded-xl bg-[#FFEFE3] text-center py-3 px-4 hover:mb-2`}
              disabled={loading}
            >
              {loading ? (
                <span className="spinner animate-spin">
                  <FaSpinner />
                </span>
              ) : (
                <span>
                  <LuPlay className="text-[#FF6B00] h-[18px] " />
                </span>
              )}
            </button>
            <p className="text-sm text-[#FF6B00] text-semibold text-center mt-1">
              {loading ? "Уншиж байна" : "Туршиж үзэх"}
            </p>
          </div> */}
          <div className="flex flex-col items-center">
            <button
              className={`rounded-xl bg-[#E3EBFF] text-center py-3 px-4 hover:mb-2`}
              disabled={loading}
              onClick={createOna}
            >
              {loading ? (
                <span className="spinner">
                  <FaSpinner className="spin-animation" />
                </span>
              ) : (
                <span>
                  <svg
                    width="16"
                    height="20"
                    viewBox="0 0 16 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 5.77L12.28 16.2L8.81 14.67L8 14.31L7.19 14.67L3.72 16.2L8 5.77ZM8 0.5L0.5 18.79L1.21 19.5L8 16.5L14.79 19.5L15.5 18.79L8 0.5Z"
                      fill="#084DFF"
                    />
                  </svg>
                </span>
              )}
            </button>
            <p className="text-sm text-[#084DFF] text-semibold text-center mt-1">
              {loading ? "Уншиж байна" : "Илгээх"}
            </p>
          </div>
        </div>
      </div>
      <ToastContainer />
      {popUp && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-xl p-6 w-[548px] h-[329px] flex flex-col justify-center">
            <div className="flex items-center justify-center">
              <img src="/survey/surveyPop.png" alt="pop" />
            </div>
            <div className="text-center mt-4">
              <p className="text-[24px] font-bold text-[#444]">
                Амжилттай нийтлэгдлээ!
              </p>
              <p className="text-[#616161] text-[20px] mt-2">
                Таны үүсгэсэн асуумж сонгосон ажилтанд <br /> амжилттай
                илгээгдлээ.
              </p>
              <button
                onClick={() => {
                  navigate("/company/softSkill/report");
                }}
                className="mt-4 px-8 py-2 bg-[#222] text-lg rounded-xl text-white"
              >
                Дуусгах
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SoftSkillCreate;
