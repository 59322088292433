import React, { useState } from "react";
import { LuClock3 } from "react-icons/lu";
import { LuClock9 } from "react-icons/lu";
import { MdOutlineModeEdit } from "react-icons/md";

const MeetingInfo = () => {
  const [schedule, setSchedule] = useState([
    { day: "Monday", enabled: true, time: "09:00", endtime: "17:00" },
    { day: "Tuesday", enabled: true, time: "09:00", endtime: "17:00" },
    { day: "Wednesday", enabled: true, time: "09:00", endtime: "17:00" },
    { day: "Thursday", enabled: true, time: "09:00", endtime: "17:00" },
    { day: "Friday", enabled: true, time: "09:00", endtime: "17:00" },
    { day: "Saturday", enabled: true, time: "09:00", endtime: "17:00" },
    { day: "Sunday", enabled: true, time: "09:00", endtime: "17:00" },
  ]);

  return (
    <div className="flex justify-center">
      <div className="w-[745px] bg-[#fff] py-6 px-8">
        <div className="py-3 mb-8">
          <div className="flex items-center gap-2">
            <LuClock3 className="text-[#575763]" />
            <p className="text-[#575763] text-sm">30 минут</p>
          </div>
          <div className="flex items-start gap-2 mt-3">
            <div className="w-[16px]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
              >
                <path
                  d="M10.5 1.6665C7.27496 1.6665 4.66663 4.27484 4.66663 7.49984C4.66663 11.8748 10.5 18.3332 10.5 18.3332C10.5 18.3332 16.3333 11.8748 16.3333 7.49984C16.3333 4.27484 13.725 1.6665 10.5 1.6665ZM6.33329 7.49984C6.33329 5.19984 8.19996 3.33317 10.5 3.33317C12.8 3.33317 14.6666 5.19984 14.6666 7.49984C14.6666 9.89984 12.2666 13.4915 10.5 15.7332C8.76663 13.5082 6.33329 9.87484 6.33329 7.49984Z"
                  fill="#575763"
                />
                <path
                  d="M10.5 9.58317C11.6506 9.58317 12.5833 8.65043 12.5833 7.49984C12.5833 6.34924 11.6506 5.4165 10.5 5.4165C9.34937 5.4165 8.41663 6.34924 8.41663 7.49984C8.41663 8.65043 9.34937 9.58317 10.5 9.58317Z"
                  fill="#575763"
                />
              </svg>
            </div>
            <p className="text-[#575763] text-sm">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </p>
          </div>
        </div>
        <div>
          {schedule.map((item, index) => (
            <div
              className={`w-full flex items-center justify-between pb-2.5 ${
                index === 0 ? "" : "border-t border-[#D1D5DB] pt-2.5"
              }`}
            >
              <div className="flex items-center gap-2">
                <p className="text-sm text-[#111827]">{item.day}</p>
                {!item.enabled && (
                  <p className="text-sm text-[#4B5563]">
                    Энэ өдөр уулзалт товлохгүй
                  </p>
                )}
              </div>
              {item.enabled && (
                <div className="flex items-center gap-3">
                  <div className="flex items-center gap-2 px-1 py-0.5">
                    <LuClock9 className="text-[#6B7280]" />
                    <p className="text-[#111827] text-sm">{item.time}</p>
                  </div>
                  <p className="text-sm text-[#6B7280]">to</p>
                  <div className="flex items-center gap-2 px-1 py-0.5">
                    <LuClock9 className="text-[#6B7280]" />
                    <p className="text-[#111827] text-sm">{item.endtime}</p>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="mt-8 flex justify-end gap-4">
          <button className="text-sm flex items-center gap-2 p-3 border border-[#CECFD3] rounded-lg">
            <MdOutlineModeEdit />
            Мэдээлэл өөрчлөх
          </button>
          <button className="text-sm flex items-center gap-2 p-3 bg-[#FF4A39] text-[#FF4A39] bg-opacity-10 rounded-lg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
            >
              <path
                d="M11.1666 6V12.6667H5.83325V6H11.1666ZM10.1666 2H6.83325L6.16659 2.66667H3.83325V4H13.1666V2.66667H10.8333L10.1666 2ZM12.4999 4.66667H4.49992V12.6667C4.49992 13.4 5.09992 14 5.83325 14H11.1666C11.8999 14 12.4999 13.4 12.4999 12.6667V4.66667Z"
                fill="#FF4A39"
              />
            </svg>
            Уулзалтын загвар устгах
          </button>
        </div>
      </div>
    </div>
  );
};

export default MeetingInfo;
