import React, { useEffect, useState } from "react";
import { Line, Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {
  getBurnOutDepartment,
  takeList,
  takeReport,
  getBurnoutGraph,
} from "../../../services/burnout.service";
import Loading from "../../../components/uncommon/Loading";
import { useTranslation } from "react-i18next";
import "../../../css/additional-styles/costum-burnout-input.scss";
import { FaMinus } from "react-icons/fa";
import { FaPlus } from "react-icons/fa6";
import { Link } from "react-router-dom";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Dropdown = ({
  options = [],
  onSelect,
  selectedId,
  defaultLabel = "Select an option",
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelect = (option) => {
    if (option.id === null) {
      onSelect(null);
    } else {
      onSelect(option.id);
    }
  };

  const optionsWithDefault = [{ id: null, label: defaultLabel }, ...options];

  return (
    <div className="w-64">
      <button
        onClick={toggleDropdown}
        className="w-full bg-white text-left px-4 py-2 rounded-md shadow-md focus:outline-none"
      >
        <div className="flex justify-between items-center">
          <span className="text-[#324D72] text-sm font-semibold">
            {optionsWithDefault.find((option) => option?.id === selectedId)
              ?.label || defaultLabel}
          </span>
          <svg
            className={`w-5 h-5 transform transition-transform ${
              isOpen ? "rotate-180" : ""
            }`}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 9l-7 7-7-7"
            ></path>
          </svg>
        </div>
      </button>

      {isOpen && (
        <div className="bg-white rounded-md shadow-lg overflow-hidden max-h-[350px] overflow-y-scroll ">
          <ul className="divide-y divide-gray-200">
            {optionsWithDefault.map((option) => (
              <li
                key={option?.id}
                onClick={() => handleSelect(option)}
                className={`px-4 py-2 cursor-pointer hover:bg-gray-100 text-sm ${
                  option.id === selectedId ? "bg-[#E9EDF5]" : ""
                }`}
              >
                {option?.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
const BurnOutReportTable = () => {
  const [data, setData] = useState(null);
  const [list, setList] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedList, setSelectedList] = useState(null);
  const [secondData, setSecondData] = useState(null);
  const [graphData, setGraphData] = useState(null);
  const [graphSecondData, setGraphSecondData] = useState(null);
  const [combinedData, setCombinedData] = useState({
    totalEmp: 0,
    totalCompleted: 0,
    completedPercent: 0,
    averageBurnout: 0,
    averageCognitive: 0,
    averageMental: 0,
    averagePhysical: 0,
    lowest: "",
    highest: "",
    lvl5: 0,
    lvl4: 0,
    lvl3: 0,
    lvl2: 0,
    lvl1: 0,
  });

  const { t } = useTranslation();

  useEffect(() => {
    takeReport()
      .then((data) => {
        setData(data.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
    takeList()
      .then((data) => {
        setList(data.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
    getBurnoutGraph()
      .then((data) => {
        setGraphData(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (selectedDepartment !== null && selectedList === null) {
      getBurnOutDepartment(null, selectedDepartment)
        .then((data) => {
          setSecondData(data.data);
        })
        .catch((err) => {
          console.log(err);
        });
      getBurnoutGraph(selectedDepartment)
        .then((data) => {
          setGraphSecondData(data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (selectedDepartment === null && selectedList !== null) {
      console.log("2");
      getBurnOutDepartment(selectedList, null)
        .then((data) => {
          setSecondData(data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (selectedDepartment !== null && selectedList !== null) {
      getBurnOutDepartment(selectedList, selectedDepartment)
        .then((data) => {
          console.log(data.data);
          setSecondData(data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedDepartment, selectedList]);

  useEffect(() => {
    if (data !== null && secondData === null) {
      const totalEmp = data.reduce((sum, dep) => sum + dep.employeeCount, 0);
      const totalCompleted = data.reduce(
        (sum, dep) => sum + dep.completedCount,
        0
      );
      const completedPercent =
        totalEmp > 0 ? (totalCompleted / totalEmp) * 100 : 0;

      const averageBurnout =
        totalCompleted > 0
          ? (
              data.reduce((acc, cur) => {
                if (cur.completedCount > 0) {
                  return (
                    acc + cur.averageBurnoutLevelCompleted * cur.completedCount
                  );
                }
                return acc;
              }, 0) / totalCompleted
            ).toFixed(1)
          : 0;

      const filteredData = data.filter(
        (department) => department.averageBurnoutLevelCompleted !== null
      );

      const highest = filteredData.reduce(
        (max, department) =>
          department.averageBurnoutLevelCompleted >
          max.averageBurnoutLevelCompleted
            ? department
            : max,
        filteredData[0]
      );

      const lowest = filteredData.reduce(
        (min, department) =>
          department.averageBurnoutLevelCompleted <
          min.averageBurnoutLevelCompleted
            ? department
            : min,
        filteredData[0]
      );

      // Calculate percentages for each level
      const levelPercentages = data.reduce(
        (acc, cur) => {
          acc.lvl1 += cur.lvl1 || 0;
          acc.lvl2 += cur.lvl2 || 0;
          acc.lvl3 += cur.lvl3 || 0;
          acc.lvl4 += cur.lvl4 || 0;
          acc.lvl5 += cur.lvl5 || 0;
          return acc;
        },
        { lvl1: 0, lvl2: 0, lvl3: 0, lvl4: 0, lvl5: 0 }
      );

      const lvl1Percent =
        totalCompleted > 0 ? (levelPercentages.lvl1 / totalCompleted) * 100 : 0;
      const lvl2Percent =
        totalCompleted > 0 ? (levelPercentages.lvl2 / totalCompleted) * 100 : 0;
      const lvl3Percent =
        totalCompleted > 0 ? (levelPercentages.lvl3 / totalCompleted) * 100 : 0;
      const lvl4Percent =
        totalCompleted > 0 ? (levelPercentages.lvl4 / totalCompleted) * 100 : 0;
      const lvl5Percent =
        totalCompleted > 0 ? (levelPercentages.lvl5 / totalCompleted) * 100 : 0;

      setCombinedData({
        totalEmp,
        totalCompleted,
        completedPercent: parseFloat(completedPercent.toFixed(1)),
        averageBurnout: parseFloat(averageBurnout),
        averageCognitive: parseFloat(
          (
            data.reduce((acc, cur) => {
              if (cur.completedCount > 0) {
                return (
                  acc + cur.averageCognitivelLevelCompleted * cur.completedCount
                );
              }
              return acc;
            }, 0) / totalCompleted
          ).toFixed(1)
        ),
        averageMental: parseFloat(
          (
            data.reduce((acc, cur) => {
              if (cur.completedCount > 0) {
                return (
                  acc + cur.averageMentalLevelCompleted * cur.completedCount
                );
              }
              return acc;
            }, 0) / totalCompleted
          ).toFixed(1)
        ),
        averagePhysical: parseFloat(
          (
            data.reduce((acc, cur) => {
              if (cur.completedCount > 0) {
                return (
                  acc + cur.averagePhysicalLevelCompleted * cur.completedCount
                );
              }
              return acc;
            }, 0) / totalCompleted
          ).toFixed(1)
        ),
        lowest: lowest ? lowest.departmentName : "",
        highest: highest ? highest.departmentName : "",
        lvl1: parseFloat(lvl1Percent.toFixed(1)),
        lvl2: parseFloat(lvl2Percent.toFixed(1)),
        lvl3: parseFloat(lvl3Percent.toFixed(1)),
        lvl4: parseFloat(lvl4Percent.toFixed(1)),
        lvl5: parseFloat(lvl5Percent.toFixed(1)),
      });
    }

    if (secondData !== null && data !== null) {
      const totalEmp = secondData.length;

      const completedPlayers = secondData.filter(
        (item) => item.completeType === "completed"
      );

      const completedCount = secondData.filter(
        (item) => item.completeType === "completed"
      ).length;

      const totalBurnoutLevel = secondData.reduce((acc, player) => {
        if (player.averageBurnoutLevelCompleted) {
          return acc + parseFloat(player.averageBurnoutLevelCompleted);
        }
        return acc;
      }, 0);

      const totalMentalLevel = completedPlayers.reduce((acc, player) => {
        return acc + (player.averageMentalLevelCompleted || 0);
      }, 0);
      const averageMentalLevelCompleted =
        completedCount > 0
          ? parseFloat((totalMentalLevel / completedCount).toFixed(1))
          : 0;

      const totalCognitiveLevel = completedPlayers.reduce((acc, player) => {
        return acc + (player.averageCognitiveLevelCompleted || 0);
      }, 0);
      const averageCognitiveLevelCompleted =
        completedCount > 0
          ? parseFloat((totalCognitiveLevel / completedCount).toFixed(1))
          : 0;

      const totalPhysicalLevel = completedPlayers.reduce((acc, player) => {
        return acc + (player.averagePhysicallLevelCompleted || 0);
      }, 0);
      const averagePhysicalLevelCompleted =
        completedCount > 0
          ? parseFloat((totalPhysicalLevel / completedCount).toFixed(1))
          : 0;

      const averageBurnoutLevelCompleted =
        completedCount > 0
          ? (totalBurnoutLevel / completedCount).toFixed(1)
          : 0;

      const completedPercent =
        totalEmp > 0 ? (completedCount / totalEmp) * 100 : 0;

      setCombinedData({
        totalEmp: totalEmp,
        totalCompleted: completedCount,
        completedPercent: completedPercent.toFixed(1),
        averageBurnout: averageBurnoutLevelCompleted,
        averageCognitive: averageCognitiveLevelCompleted,
        averageMental: averageMentalLevelCompleted,
        averagePhysical: averagePhysicalLevelCompleted,
        lowest: "",
        highest: "",
        lvl5: 0,
        lvl4: 0,
        lvl3: 0,
        lvl2: 0,
        lvl1: 0,
      });
    }
  }, [data, secondData]);

  const handleSelect = (id, type) => {
    if (type === "department") {
      setSelectedDepartment(id);
      setGraphSecondData(null);
      setSecondData(id === null ? null : secondData);
    } else if (type === "list") {
      setSelectedList(id);
      setSecondData(id === null ? null : secondData);
    }
  };

  const labels = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  const ChartData = {
    labels: labels,
    datasets: [
      {
        label: "Burnout Level",
        data: labels.map((label, index) => {
          const monthData =
            graphSecondData !== null
              ? graphSecondData?.find((item) => item.month === index + 1)
              : graphData?.find((item) => item.month === index + 1);
          return monthData ? monthData.avgLevel : 0;
        }),
        borderColor: "#5A6ACF",
        backgroundColor: "#5A6ACF",
        fill: false,
        tension: 0.4,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        max: 5,
        ticks: {
          stepSize: 1,
          callback: function (value, index, values) {
            return value.toFixed(0);
          },
          color: function (context) {
            const tickValue = context.tick.value;
            switch (tickValue) {
              case 1:
                return "#34D399";
              case 2:
                return "#10B981";
              case 3:
                return "#FBBF24";
              case 4:
                return "#FB923C";
              case 5:
                return "#EF4444";
              default:
                return "#000000";
            }
          },
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const doghChart = {
    labels: [
      "Engaged",
      "Inefficient",
      "Хэт ачаалалтай",
      "Анхаарах хэрэгтэй",
      "Burnout",
    ],
    datasets: [
      {
        data: [
          combinedData.lvl5,
          combinedData.lvl4,
          combinedData.lvl3,
          combinedData.lvl2,
          combinedData.lvl1,
        ],
        backgroundColor: [
          "#06B496",
          "#F0CB6D",
          "#FF9D72",
          "#F18B8B",
          "#DF2D2D",
        ],
        cutout: "60%",
        borderRadius: 8,
      },
    ],
  };

  if (data === null) {
    return <Loading />;
  }

  const departmentOptions =
    data?.map((item) => ({
      label: item.departmentName,
      id: item.departmentId,
    })) || [];

  const listOptions =
    list?.map((item) => ({
      label: item.name,
      id: item.id,
    })) || [];

  const renderStatus = (burnoutLevel) => {
    if (burnoutLevel === null) {
      return (
        <div className="flex items-center">
          <div className="w-2 h-2 flex items-center justify-center mr-1">
            <img src="/icons/grayDot.png" alt="" />
          </div>
          <p className="text-[#324D72] text-sm">No result</p>
        </div>
      );
    } else if (burnoutLevel >= 1 && burnoutLevel < 2) {
      return (
        <div>
          <div className="flex justify-center">
            <img className="h-[17px]" src="/burnout/1.svg" alt="icon" />
          </div>
          <p className="text-center text-[#324D72] text-sm">Burnout</p>
        </div>
      );
    } else if (burnoutLevel >= 2 && burnoutLevel < 3) {
      return (
        <div>
          <div className="flex justify-center">
            <img className="h-[17px]" src="/burnout/2.svg" alt="icon" />
          </div>
          <p className="text-center text-[#324D72] text-sm">Disengaged</p>
        </div>
      );
    } else if (burnoutLevel >= 3 && burnoutLevel < 4) {
      return (
        <div>
          <div className="flex justify-center">
            <img className="h-[17px]" src="/burnout/3.svg" alt="icon" />
          </div>
          <p className="text-center text-[#324D72] text-sm">Overextended</p>
        </div>
      );
    } else if (burnoutLevel >= 4 && burnoutLevel < 5) {
      return (
        <div>
          <div className="flex justify-center">
            <img className="h-[17px]" src="/burnout/4.svg" alt="icon" />
          </div>
          <p className="text-center text-[#324D72] text-sm">Ineffective</p>
        </div>
      );
    } else if (burnoutLevel >= 5) {
      return (
        <div>
          <div className="flex justify-center">
            <img className="h-[17px]" src="/burnout/5.svg" alt="icon" />
          </div>
          <p className="text-center text-[#324D72] text-sm">Engaged</p>
        </div>
      );
    } else {
      return (
        <div className="flex items-center">
          <div className="w-2 h-2 flex items-center justify-center mr-1">
            <img src="/icons/grayDot.png" alt="" />
          </div>
          <p className="text-[#324D72] text-sm">
            {t("burnout.generalReport.noResponse")}
          </p>
        </div>
      );
    }
  };

  const renderActionStatus = (completeType) => {
    switch (completeType) {
      case "completed":
        return (
          <div className="flex items-center">
            <p className="text-[#324D72] text-sm">
              {t("Assessment.completed")}
            </p>
          </div>
        );
      case "started":
        return (
          <div className="flex items-center">
            <p className="text-[#324D72] text-sm">{t("Assessment.started")}</p>
          </div>
        );
      default:
        return (
          <div className="flex items-center">
            <p className="text-[#324D72] text-sm">
              {t("Assessment.sendInvitation")}
            </p>
          </div>
        );
    }
  };

  const renderBurnoutLevel = (burnoutLevel) => {
    if (burnoutLevel === null) {
      return (
        <div className="flex items-center">
          <div className="w-2 h-2 flex items-center justify-center mr-1">
            <img src="/icons/grayDot.png" alt="" />
          </div>
          <p>No result</p>
        </div>
      );
    } else if (burnoutLevel >= 1 && burnoutLevel < 2) {
      return (
        <div className="flex items-center">
          <div className="w-2 h-2 flex items-center justify-center mr-1">
            <img src="/icons/redDot.png" alt="Burnout Level 1" />
          </div>
          <p>1-{t("burnout.generalReport.burnout")}</p>
        </div>
      );
    } else if (burnoutLevel >= 2 && burnoutLevel < 3) {
      return (
        <div className="flex items-center">
          <div className="w-2 h-2 flex items-center justify-center mr-1">
            <img src="/icons/orangeDot.png" alt="Burnout Level 2" />
          </div>
          <p>2-{t("burnout.generalReport.payAttention")}</p>
        </div>
      );
    } else if (burnoutLevel >= 3 && burnoutLevel < 4) {
      return (
        <div className="flex items-center">
          <div className="w-2 h-2 flex items-center justify-center mr-1">
            <img src="/icons/purpleDot.png" alt="Burnout Level 3" />
          </div>
          <p>3-{t("burnout.generalReport.overloaded")}</p>
        </div>
      );
    } else if (burnoutLevel >= 4 && burnoutLevel < 5) {
      return (
        <div className="flex items-center">
          <div className="w-2 h-2 flex items-center justify-center mr-1">
            <img src="/icons/blueDot.png" alt="Burnout Level 4" />
          </div>
          <p>4-{t("burnout.generalReport.decreasedProd")}</p>
        </div>
      );
    } else if (burnoutLevel === 5) {
      return (
        <div className="flex items-center">
          <div className="w-2 h-2 flex items-center justify-center mr-1">
            <img src="/icons/greenEllipse.png" alt="Burnout Level 5" />
          </div>
          <p>5-{t("burnout.generalReport.Productive")}</p>
        </div>
      );
    } else {
      return (
        <div className="flex items-center">
          <div className="w-2 h-2 flex items-center justify-center mr-1">
            <img src="/icons/grayDot.png" alt="No Response" />
          </div>
          <p>{t("burnout.generalReport.noResponse")}</p>
        </div>
      );
    }
  };

  return (
    <div className="pt-11">
      <div className="w-full flex items-start gap-5">
        <div className="p-4 bg-white rounded-lg shadow-md w-[60%]">
          <p className="font-semibold text-[#1E293B] mb-4">
            {t("burnout.generalReport.prevMonths")}
          </p>
          <Line data={ChartData} options={options} />
          <div className="mt-6 flex gap-4 items-center">
            <div>
              <p className="text-[#4B4B53] text-sm">
                {t("burnout.generalReport.levelParticipation")}
              </p>
              <p className="text-3xl font-bold">
                {combinedData.completedPercent}%
              </p>
            </div>
            <div>
              <p className="text-[#4B4B53] text-sm">
                {t("burnout.generalReport.totalParticipation")}
              </p>
              <p className="text-3xl font-bold">
                {combinedData.totalCompleted}/{combinedData.totalEmp}
              </p>
            </div>
          </div>
        </div>
        <div className="w-[40%] flex items-start gap-5">
          <Dropdown
            options={departmentOptions}
            selectedId={selectedDepartment}
            onSelect={(id) => handleSelect(id, "department")}
            defaultLabel={t("burnout.generalReport.wholeCompany")}
          />

          <Dropdown
            options={listOptions}
            selectedId={selectedList}
            onSelect={(id) => handleSelect(id, "list")}
            defaultLabel={t("burnout.generalReport.wholeCompany")}
          />
        </div>
      </div>{" "}
      <div className="flex items-start gap-10 mt-10">
        <div className="shadow-xl px-4 py-4 rounded-xl w-[40%] mt-10 bg-[#fff]">
          <div className="flex flex-col justify-between w-[180px]">
            <p className="text-[#1E293B] mb-2 mt-4">
              {t("burnout.generalReport.generalBurnout")} -{" "}
              {/* {isNaN(averageBurnout) ? "0" : averageBurnout.toFixed(1)} */}
              {combinedData.averageBurnout}
            </p>
            <div>
              <img src="/burnoutRe.png" alt="emote" />
              <div className="burnout-range-wrapper mt-2 flex items-center gap-1">
                <FaMinus className="text-xs text-[#e63946]" />
                <input
                  type="range"
                  className="w-full burnout-range"
                  value={combinedData.averageBurnout}
                  min={1}
                  max={5}
                  step={0.1}
                  readOnly
                />
                <FaPlus className="text-xs text-[#4caf50]" />
              </div>
            </div>
          </div>
          <div
            className={`${
              secondData !== null ? "hidden" : "flex"
            }  items-start gap-6 mt-6`}
          >
            <div style={{ width: "120px" }}>
              <Doughnut
                data={doghChart}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}
              />
            </div>
            <div className="w-full">
              <div className="flex gap-2 items-center mb-2 w-full">
                <div className="w-2 h-2 bg-[#06B496] rounded-full" />
                <div className="flex justify-between items-center w-full">
                  <p className="text-xs">
                    {t("burnout.generalReport.Productive")}
                  </p>
                  <p className="text-xs">{combinedData.lvl5}%</p>
                </div>
              </div>
              <div className="flex gap-2 items-center mb-2 w-full">
                <div className="w-2 h-2 bg-[#F0CB6D] rounded-full" />
                <div className="flex justify-between items-center w-full">
                  <p className="text-xs">
                    {t("burnout.generalReport.decreasedProd")}
                  </p>
                  <p className="text-xs">{combinedData.lvl4}%</p>
                </div>
              </div>
              <div className="flex gap-2 items-center mb-2 w-full">
                <div className="w-2 h-2 bg-[#FF9D72] rounded-full" />
                <div className="flex justify-between items-center w-full">
                  <p className="text-xs">
                    {t("burnout.generalReport.overloaded")}
                  </p>
                  <p className="text-xs">{combinedData.lvl3}%</p>
                </div>
              </div>
              <div className="flex gap-2 items-center mb-2 w-full">
                <div className="w-2 h-2 bg-[#F18B8B] rounded-full" />
                <div className="flex justify-between items-center w-full">
                  <p className="text-xs">
                    {t("burnout.generalReport.payAttention")}
                  </p>
                  <p className="text-xs">{combinedData.lvl2}%</p>
                </div>
              </div>

              <div className="flex gap-2 items-center mb-2 w-full">
                <div className="w-2 h-2 bg-[#DF2D2D] rounded-full" />
                <div className="flex justify-between items-center w-full">
                  <p className="text-xs">Burnout</p>
                  <p className="text-xs">{combinedData.lvl1}%</p>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`${
              secondData !== null ? "hidden" : "flex"
            } justify-between items-center mt-8 gap-2`}
          >
            <div className="border rounded-xl p-2 border-[#48F81C]">
              <p className="text-sm opacity-70">
                {t("Dashboard.burnoutLowDep")}
              </p>
              <p className="my-2">{combinedData.highest}</p>
              <img src="/burnout/5.svg" alt="icon" />
            </div>
            <div className="border rounded-xl p-2 border-[#F81B1B] border-opacity-30">
              <p className="text-sm opacity-70">
                {t("Dashboard.burnoutHighDep")}
              </p>
              <p className="my-2">{combinedData.lowest}</p>
              <img src="/burnout/1.svg" alt="icon" />
            </div>
          </div>
        </div>
        <div>
          <div className="flex justify-center mb-6">
            <div className="p-5 flex flex-col justify-between shadow-xl rounded-lg w-[300px] bg-[#fff]">
              <p className="text-[#324d72] font-bold text-center ">
                {t("burnout.generalReport.generalPsychologic")} -{" "}
                {/* {isNaN(averageMental) ? "0" : averageMental.toFixed(1)} */}
                {combinedData.averageMental}
              </p>
              <div className="flex justify-center">
                <div className="w-[230px] mt-2 ">
                  <img src="/burnoutRe.png" alt="emote" />
                  <div className="burnout-range-wrapper mt-2 flex items-center gap-1">
                    <FaMinus className="text-xs text-[#e63946]" />
                    <input
                      type="range"
                      className="w-full burnout-range"
                      value={combinedData.averageMental}
                      min={1}
                      max={5}
                      step={0.1}
                      readOnly
                    />
                    <FaPlus className="text-xs text-[#4caf50]" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center mb-6">
            <div className="p-5 flex flex-col justify-between shadow-xl rounded-lg w-[300px] bg-[#fff]">
              <p className="text-[#324d72] font-bold text-center ">
                {t("burnout.generalReport.intellectualProductivity")} -{" "}
                {/* {isNaN(averageCognitive) ? "0" : averageCognitive.toFixed(1)} */}
                {combinedData.averageCognitive}
              </p>
              <div className="flex justify-center">
                <div className="w-[230px] mt-2 ">
                  <img src="/burnoutRe.png" alt="emote" />
                  <div className="burnout-range-wrapper mt-2 flex items-center gap-1">
                    <FaMinus className="text-xs text-[#e63946]" />
                    <input
                      type="range"
                      className="w-full burnout-range"
                      value={combinedData.averageCognitive}
                      min={1}
                      max={5}
                      step={0.1}
                      readOnly
                    />
                    <FaPlus className="text-xs text-[#4caf50]" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center mb-6">
            <div className="p-5 flex flex-col justify-between shadow-xl rounded-lg w-[300px] bg-[#fff]">
              <p className="text-[#324d72] font-bold text-center ">
                {t("burnout.generalReport.fatigue")} -{" "}
                {/* {isNaN(averagePhysical) ? "0" : averagePhysical.toFixed(1)} */}
                {combinedData.averagePhysical}
              </p>
              <div className="flex justify-center">
                <div className="w-[230px] mt-2 ">
                  <img src="/burnoutRe.png" alt="emote" />
                  <div className="burnout-range-wrapper mt-2 flex items-center gap-1">
                    <FaMinus className="text-xs text-[#e63946]" />
                    <input
                      type="range"
                      className="w-full burnout-range"
                      value={combinedData.averagePhysical}
                      min={1}
                      max={5}
                      step={0.1}
                      readOnly
                    />
                    <FaPlus className="text-xs text-[#4caf50]" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {secondData !== null ? (
        <div className="my-10">
          <table className="min-w-full bg-white shadow-xl">
            <thead>
              <tr>
                <th className="px-6 py-3 text-left text-sm text-[#1E293B] font-semibold">
                  {t("burnout.generalReport.fullName")}
                </th>
                <th className="px-6 py-3 text-left text-sm text-[#1E293B] font-semibold">
                  {t("burnout.generalReport.email")}
                </th>
                <th className="px-6 py-3 text-left text-sm text-[#1E293B] font-semibold">
                  {t("burnout.generalReport.status")}
                </th>
                <th className="px-6 py-3 text-left text-sm text-[#1E293B] font-semibold">
                  {t("burnout.generalReport.level")}
                </th>
              </tr>
            </thead>
            <tbody>
              {secondData?.map((item, index) => (
                <tr
                  key={index}
                  className={`${index % 2 === 0 ? "bg-gray-50" : "bg-white"}`}
                >
                  <td className="px-6 py-4 text-sm text-[#324D72] font-bold">
                    <Link
                      to={`/company/burnout/report/demo/${item.burnoutPlayerId}`} // Replace with your dynamic URL
                      className="flex items-center h-full w-full"
                    >
                      {item.firstName}
                    </Link>
                  </td>
                  <td className="px-6 py-4 text-sm text-[#324D72] font-semibold">
                    <Link
                      to={`/company/burnout/report/demo/${item.burnoutPlayerId}`} // Replace with your dynamic URL
                      className="flex items-center h-full w-full"
                    >
                      -
                    </Link>
                  </td>
                  <td className="px-6 py-4 text-sm text-[#324D72] font-semibold flex items-center">
                    <Link
                      to={`/company/burnout/report/demo/${item.burnoutPlayerId}`} // Replace with your dynamic URL
                      className="flex items-center h-full w-full"
                    >
                      {renderActionStatus(item.completeType)}
                    </Link>
                  </td>
                  <td className="px-6 py-4 text-sm text-[#324D72] font-semibold">
                    <Link
                      to={`/company/burnout/report/demo/${item.burnoutPlayerId}`} // Replace with your dynamic URL
                      className="flex items-center h-full w-full"
                    >
                      {renderBurnoutLevel(item.averageBurnoutLevelCompleted)}
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="my-10">
          <table className="min-w-full bg-white shadow-xl">
            <thead>
              <tr>
                <th className="px-6 py-3 text-left text-sm text-[#1E293B] font-semibold">
                  {t("burnout.generalReport.department")}
                </th>
                <th className="px-6 py-3 text-left text-sm text-[#1E293B] font-semibold">
                  {t("burnout.generalReport.total")}
                </th>
                <th className="px-6 py-3 text-left text-sm text-[#1E293B] font-semibold">
                  {t("burnout.generalReport.average")}
                </th>
                <th className="px-6 py-3 text-left text-sm text-[#1E293B] font-semibold">
                  {t("burnout.generalReport.PayAttention")}
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr
                  key={index}
                  className={`${index % 2 === 0 ? "bg-gray-50" : "bg-white"}`}
                >
                  <td className="px-6 py-4 text-sm text-[#324D72] font-bold">
                    {item.departmentName}
                  </td>
                  <td className="px-6 py-4 text-sm text-[#324D72] font-semibold">
                    {item.completedCount} / {item.employeeCount}
                  </td>
                  <td className="px-6 py-4 text-sm text-[#324D72] font-semibold flex items-center">
                    {renderStatus(
                      item.averageBurnoutLevelCompleted
                        ? item.averageBurnoutLevelCompleted
                        : 0
                    )}
                  </td>
                  <td className="px-6 py-4 text-sm text-[#324D72] font-semibold">
                    {item.lvl1}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default BurnOutReportTable;
