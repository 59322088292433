import React, { useState } from "react";
import MeetingInfo from "./MeetingInfo";
import MeetingTable from "./MeetingTable";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa6";

const Meeting = () => {
  const [page, setPage] = useState("table");
  const navigate = useNavigate();

  const handleChangePage = (page) => {
    setPage(page);
    navigate(`${page}`);
  };

  const renderActivePage = () => {
    switch (page) {
      case "table":
        return <MeetingTable />;
      case "info":
        return <MeetingInfo />;
      default:
        return null;
    }
  };

  return (
    <div className="pt-11 w-10/12 m-auto">
      <div className="mb-4">
        <button
          onClick={() => navigate("/company/meetings")}
          className="flex items-center gap-2 text-[#1E293B] text-sm"
        >
          <FaArrowLeft />
          Буцах
        </button>
      </div>
      <div className="flex items-center justify-between mb-10">
        <p className="text-[24px] text-[#222]">Уулзалтын нэр</p>
        <div className="flex items-end gap-8">
          <button
            className={`pb-2 text-sm ${
              page === "table"
                ? "text-[#2C4360] border-b-2 border-[#2C4360]"
                : "text-[#666874]"
            }`}
            onClick={() => {
              handleChangePage("table");
            }}
          >
            Уулзалт товлосон хүмүүс
          </button>
          <button
            className={`pb-2 text-sm ${
              page === "info"
                ? "text-[#2C4360] border-b-2 border-[#2C4360]"
                : "text-[#666874]"
            }`}
            onClick={() => {
              handleChangePage("info");
            }}
          >
            Уулзалтын мэдээлэл
          </button>
        </div>
        <div className="w-[163px] h-[2px]"></div>
      </div>
      <div>{renderActivePage()}</div>
    </div>
  );
};

export default Meeting;
